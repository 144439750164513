<template>
  <div class="context-box">
    <div class="context-box__text">
      <p class="text-title-context">What:</p>
    </div>
    <b-row class="context-box__panel">
      <b-col cols="3">
        <p class="text-title-context">Country:</p>
        <cp-simple-select-custom
          v-model="nwsCountrySelected"
          :options="countries"
          :disabled="allNewspapers.length == 0"
          @input="changeSelectPlace"
        />
      </b-col>
      <b-col cols="">
        <p class="text-title-context">Newspaper:</p>
        <cp-simple-select-custom
          v-model="nwsNameSelected"
          :options="nwsNames"
          :disabled="!nwsCountrySelected"
          @input="changeSelectName"
        />
      </b-col>
      <b-col cols="4">
        <p class="text-title-context">Section:</p>
        <cp-simple-select-custom
          v-model="nwsUrlSelected"
          :options="nwsUrls"
          :disabled="!nwsNameSelected"
          @input="changeSelectUrl"
        />
      </b-col>
      <b-col cols="1" class="context-box__panel-buttons">
        <b-button
          class=""
          size="md"
          :disabled="!nwsToAdd"
          variant="primary"
          @click="addNewDriver()"
        >
          <span class="context-box__panel-buttons__title">Add</span>
        </b-button>
      </b-col>
    </b-row>
    <span v-if="warningText != ''" class="message-error">
      <i class="fa fa-exclamation-circle"></i> {{ warningText }}</span
    >
    <!-- TABLE List add items    -->
    <b-row class="context-box__table">
      <b-col>
        <div class="context-box__table__body">
          <cp-table-driver
            :items="allDriversToAdd"
            @delete-item="deleteDriver"
            :fields="fields"
            title="Profile Page List"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import CpTableDriver from "../../components/table/CpTableDriver.vue";
import CpSimpleSelectCustom from "../../components/select/CpSimpleSelectCustom.vue";
import { nwsPaperMixin, socialNetwork } from "../../../mixins/index";
export default {
  name: "CreateNewsPaperDrivers",
  components: { CpTableDriver, CpSimpleSelectCustom },
  mixins: [ nwsPaperMixin, socialNetwork ],
  props: ["allDriversToAdd"],
  data() {
    return {
      suspensiveSelectedUrl: null
    };
  },
  methods: {
    addNewDriver() {
      if(!this.nwsToAdd) return;
      const driverToAdd = Object.assign({}, this.nwsToAdd);
      driverToAdd.active = true;
      driverToAdd.type = "nws";
      if (this.allDriversToAdd.length > 0) {
        const driverExist = this.allDriversToAdd.find(
          (d) => d.id == driverToAdd.id
        );
        if (driverExist) {
          this.showWarning("Newspaper already added.");
          return;
        }
      }
      this.$emit("driverToAdd", driverToAdd);
      this.cleanSelects();
      
    },
    deleteDriver: function (driver) {
      this.$emit("deleteDriverToAdd", driver.id, 'id');
    },
  },
  watch: {
    /* allDriversToAdd: {
      immediate: true,
      handler: function (x) {
        console.log(x);
      },
    }, */
  },
};
</script>
<style lang="scss" , scoped>
.context-box {
  padding: 12px 55px;
  &__text {
    padding-bottom: 3px;
  }
  .btn {
    font-size: 14px;
    background: #00a5ff;
  }
  &__panel {
    margin-top: 2px;
    &-buttons {
      padding-top: 22px;
      width: 95px;
      padding-left: 17px;
      &__title {
        font-size: 12px;
      }
      &__btn-isc {
        margin-left: 4px;
      }
    }
  }
  &__table {
    margin-top: 21px;
    padding: 12px 34px;
  }
}
</style>
