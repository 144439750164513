import "./set-public-path";
import "./app.scss";
import "vue-css-donut-chart/dist/vcdonut.css";
import "vue2-datepicker/index.css";
import "vue-loading-overlay/dist/vue-loading.css";
import Store from "./store/index";
import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
import clickOutside from "./lib/click-outside.directive";
import Vuex from "vuex";
import HighchartsVue from "highcharts-vue";
import Donut from "vue-css-donut-chart";
import DatePicker from "vue2-datepicker";
import Loading from "vue-loading-overlay";

import Highcharts from "highcharts";
import networkgraph from "highcharts/modules/networkgraph";
import Multiselect from 'vue-multiselect'
import { codeToken } from "./mixins/codeToken"
import { alertSetting } from "./mixins/alertSetting"
import { userObjMixin } from "./mixins/index"
sessionStorage.module = "monitor";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);


networkgraph(Highcharts);
// Anything exported from this file is importable by other in-browser modules.
Vue.use(BootstrapVue);
Vue.use(Vuex);
Vue.use(HighchartsVue);
Vue.use(Donut);
Vue.directive("custom-click-outside", clickOutside);
Vue.component("v-datepicker", DatePicker);
Vue.component('multiselect', Multiselect);
Vue.use(Loading, {
  color: "#00a5ff",
  loader: "spinner",
  width: 80,
  height: 80,
});


//REACT CONFIGURATION

export { mapActions, mapGetters, mapMutations } from "vuex";
export const store = Store;
export { default as BreadCrumb } from "./component-library/BreadCrumb.vue";
export { default as Tabs } from "./component-library/Tabs.vue";
export { default as TabsCustom } from "./component-library/TabsCustom.vue";
export { default as loaderCustom } from "./component-library/loaderCustom.vue";
export { default as Helpers } from "./lib/helpers";
export { default as CpTooltipCustom } from "./component-library/tooltips/CpTooltipCustom.vue";
export { default as CpTooltipWhiteCustom } from "./component-library/tooltips/CpTooltipWhiteCustom.vue";
export { default as CpTooltipDynamic } from "./component-library/tooltips/CpTooltipDynamic.vue";
export { default as CpTooltipIconCustom } from "./component-library/tooltips/CpTooltipIconCustom.vue";
export { default as CpTooltipDoubleCustom } from "./component-library/tooltips/CpTooltipDoubleCustom.vue";
export { default as CpTooltipTableCustom } from "./component-library/tooltips/CpTooltipTableCustom.vue";
export { default as IscModal } from "./component-library/modal/IscModal.vue";
export { default as IscModalAlerts } from "./component-library/modal/IscModalAlerts.vue";
export { default as CloseButton } from "./component-library/components/button/CloseButton.vue";
export { default as CpWarningInfo } from "./component-library/warnings/CpWarningInfo.vue";
export { default as CpWarningMessage } from "./component-library/warnings/CpWarningMessage.vue";
export { default as SelectCustom } from "./component-library/components/select/SelectCustom.vue";
export { default as MultiSelectCustom } from "./component-library/components/select/MultiSelectCustom.vue";
export { default as IscCreateContext } from "./component-library/listen/modal/IscCreateContext.vue";
export { default as CpInputText } from "./component-library/components/input/CpInputText.vue";
export { default as CpIconSocialMedia } from "./component-library/components/icon/CpIconSocialMedia.vue";
export { default as CpIconCloseX } from "./component-library/components/icon/closeX.vue";
export { default as CpSimpleSelectCustom } from "./component-library/components/select/CpSimpleSelectCustom.vue";
export { default as CpIscFormModal } from "./component-library/modal/CpIscFormModal.vue";
export { default as IscUpdateContext } from "./component-library/listen/modal/IscUpdateContext.vue";
export const CodeToken  = codeToken;
export const AlertSetting  = alertSetting;
export const UserObjMixin = userObjMixin;
export const Dayjs = dayjs;
