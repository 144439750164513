<template>
  <div class="input-text">
    <b-form-input
      :id="id"
      :type="type"
      :placeholder="placeholder"
      class="input-text__context-name"
      v-model="model"
      :disabled="disabled"
    ></b-form-input>
    <template v-if="errors.length > 0">
      <div v-for="(error, index) in errors" :key="index">
        <div class="message-error">
          <i class="fa fa-exclamation-circle"></i> {{ error.message }}</div
        >
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "CpInputText",
  props: {
    value: {
      type: String,
      default: "",
    },
    errorSubject: {
      type: String,
      default: () => {
        return "Field";
      },
    },
    validate: {
      type: Object,
      default: () => {
        return {
          required: false,
          email: false,
          numeric: false,
          alpha: false,
          alphaNum: false,
          maxLength: 0,
          maxLengthRequired: false,
          minLength: 0,
          minLengthRequired: false,
          decimal: false,
          maxValue: 0,
          between: 0,
          password: false,
          urlRequired: false,
          noSpace: false
        };
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        // catching errors
        this.modelError(val);
      },
    },
  },
  data() {
    return {
      errors: [],
    };
  },
  mounted() {},

  methods: {
    modelError(text) {
      this.errors = [];
      // Text validation
      this.requiredText(text)
      //validate maxlength text
      this.requiredMaxLength(text);
      //validate minLength text
      this.requiredMinLength(text);
      // validate Url 
      this.requiredUrl(text);
      // validate Email 
      this.requiredEmail(text);
      // validate password
      this.requiredPassword(text);
      // validate No-Space
      this.requiredNoSpace(text)

      this.$emit("no-errors", this.errors);
    },

    //* Methods of requirement Fields 
    requiredText(text){
      if (!text && this.validate.required)
        this.errors.push({ message: `${this.errorSubject} is required.` });
    },
    requiredMaxLength(text){
      if (
        this.validate.maxLengthRequired &&
        text.length > this.validate.maxLength 
      )
        this.errors.push({
          message: `${this.errorSubject} accepts upto ${this.validate.maxLength} characters.`,
        });
    },
    requiredMinLength(text){
      if (
        this.validate.minLengthRequired &&
        text.length < this.validate.minLength 
      )
        this.errors.push({
          message: `${this.errorSubject} accepts a minimum of ${this.validate.minLength} characters.`,
        });
    },
    requiredUrl(text){
      if (this.validate.urlRequired && !this.validateUrl(text))
        this.errors.push({ message: `This ${this.errorSubject} is invalid.` });
    },
    requiredEmail(text){
      if (this.validate.email && !this.validateEmail(text))
        this.errors.push({ message: `This email is invalid.` });
    },
    requiredPassword(text){
      if (this.validate.password && !this.validatePassword(text))
        this.errors.push({ message: `${this.errorSubject} is not good, please insert Alphabet(Uppercase and Lowercase), Number and Symbol. Must not include spaces`});
    },
    requiredNoSpace(text) {
      if (this.validate.noSpace && !this.validateNoSpace(text))
        this.errors.push({ message: `${this.errorSubject} contain spaces.` });
    },
    //* functions to validate with regex
    validateUrl(text) {
      const urlRegEx = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
      return text.match(urlRegEx);
    },
    validatePassword(text){
      const regexMinusculas = /[a-z]/;
      const regexMayusculas = /[A-Z]/;
      const regexNumeros = /[0-9]/;
      const regexSymbol = /[*@?$!#%&().:_+--]+/;
      const regexNoSpace = /\s/
      if (
            regexMinusculas.test(text) &&
            regexMayusculas.test(text) &&
            regexNumeros.test(text) &&
            regexSymbol.test(text) &&
            !regexNoSpace.test(text)
        ) {
          return true;
        } else {
          return false;
        }
    },
    validateEmail(email) {
      const regexPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return regexPattern.test(email);
    },
    validateNoSpace(text) {
      const regexPattern = /\s/
      return !regexPattern.test(text)
    }
  },
};
</script>

<style lang="scss" scoped>
.input-text {
  &__context-name {
    font-family: Oxygen;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #5d6b88;
    box-shadow: 0px 2px 5px 0px rgba(197, 208, 228, 0.62) inset;
    border: solid 1px #c5d0e4;
    height: 35px;
  }
}
</style>
<style>
.message-error {
  color: red;
  font-size: 12px;
  padding-top: 4px;
}
</style>