var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tooltip",
        {
          attrs: {
            target: _vm.target,
            title: _vm.title,
            triggers: "hover",
            "custom-class": "tooltipWhite",
            variant: "light",
            placement: _vm.placement
          }
        },
        [
          _c("p", { style: { padding: _vm.paddingContainer } }, [
            _vm._v(_vm._s(_vm.message))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }