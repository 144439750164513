<template>
  <div class="w-100 position-relative">
    <div class="d-flex w-100 bg-white position-relative z-0">
      <div
        :class="`p-1 ${n === 0 ? '' : 'pr-1'}`"
        v-for="n in cant"
        :key="n"
        :style="`width : ${100 / cant}%`"
      >
        <div class="bg-mainbg" style="height: 50px !important"></div>
      </div>
    </div>
    <div class="w-100 position-absolute d-flex top-0" style="z-index: 2">
      <div class="py-1" :style="`width : ${100 / cant}%`">
        <div
          ref="bgTab"
          class="bg-white border-item-translate w-100"
          :style="`height: 50px;transform: translateX(${translate}px)`"
        ></div>
      </div>
    </div>
    <div class="position-absolute w-100 font-weight-bold d-flex top-0" style="z-index: 2">
      <div
        @click="setTabActive(index)"
        @mouseenter="translateBg($event, index)"
        @mouseleave="resetBg($event)"
        :class="`cursor-pointer ${index === 0 ? 'p-1' : 'py-1 pr-1'}`"
        :style="`width :  ${100 / cant}%`"
        v-for="(title, index) in titles"
        :key="`${index}-titles`"
      >
        <div
          :class="['w-100', 'd-flex align-items-center' ,'justify-content-center content-text', activePos == index ? 'color-text': '',tempActive == index ? 'color-text': '']"
          style="height: 50px"
        >
          {{ title.name }} {{ title.icon ? "&nbsp;" : "" }}
          <i v-if="title.icon" :class="title.icon"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabsCustom",
  props: {
    cant: {
      type: Number,
      required: true,
    },
    titles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      translate: 0,
      activePos: 0,
      tempActive: -1
    };
  },
  methods: {
    //funcion que mueve el bg del tab a una posicion especifica
    translateBg(event, pos) {
      this.tempActive= pos
      this.translate =
        pos > 0
          ? event.target.getBoundingClientRect().width * pos - 2
          : event.target.getBoundingClientRect().width * pos;
    },
    //funcion que mueve el bg al tab activo
    resetBg(event) {
      this.tempActive= -1
      this.translate =
        this.activePos > 0
          ? event.target.getBoundingClientRect().width * this.activePos - 2
          : event.target.getBoundingClientRect().width * this.activePos;
    },
    setTabActive(tab) {
      this.activePos = tab;
      this.$emit("change", tab);
    },
  },
};
</script>

<style scoped>
.border-item-translate {
  border-top: 3px solid var(--warning);
  transition: all ease 0.5s;
}
.color-text {
  color: #1778f2;
}
</style>
