export default {
  setProfileAccount(state, profile) {
    state.profileAccount = profile;
  },
  setAudienceAccount(state, audience) {
    state.audienceDetails = audience;
  },
  setPostsAccount(state, posts) {
    state.postsDetails = posts;
  },
  setEngagedAccount(state, engaged) {
    state.engageDetails = engaged;
  },
  setInteractionsAccount(state, interaction) {
    state.interactionsDetail = interaction;
  },
};
