<template>
  <div class="context-box-sn">
    <cp-panel-s-n
      :driverType="'lk'"
      :text-value.sync="newDriverPage"
      :warningText="warningText"
      :buttonDisabled="buttonDisabled"
      :errorSubject="'URL'"
      :showBtnDrivers="showBtnDrivers"
      placeholder="Paste Url Profile Linkedin Page..."
      @no-errors="errorTerm"
      @closeIscDriverModal="closeIscDriverModalIGAndLK"
      @click-create="addNewDriver"
    />
    <!-- TABLE List add items    -->
    <b-row class="context-box-sn__table">
      <b-col>
        <div class="context-box-sn__table__body">
          <cp-table-driver
            :items="allDrivers"
            @delete-item="deleteDriver"
            :fields="fields"
            title="Profile Page List"
            :isBusy="isBusyTable"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import CpPanelSN from "./components/CpPanelSN.vue";
import CpTableDriver from "../../components/table/CpTableDriver.vue";
import { socialNetwork } from "../../../mixins/socialNetwork";
export default {
  name: "CreateLinkedinDrivers",
  mixins: [ socialNetwork ],
  components: {
    CpPanelSN,
    CpTableDriver
   },
  data() {
    return {
      fields: [
        { key: "name", class: "text-row-01" },
        {
          key: "mask",
          class: "text-row-02",
        },
        {
          key: "delete",
        },
        { key: "active" },
      ],
    };
  },
  created() { },
  methods: {
    addNewDriver: async function () {
      this.buttonDisabled = true;
      if (!this.newDriverPage || this.termError.length > 0) return;

      try {
        await this.sendUrlCheck();
      } catch (error) {
        // if it needs to add more code, check old file CreateLinkedinDrivers or ask Luis Saravia
        this.showWarning("URL invalid");
      }
    },
    sendUrlCheck: async function () {
      try {
        this.buttonDisabled = true;
        this.isBusyTable = true;
        const {
          data: { lkPageCheck },
        } = await this.$store.dispatch("settings/listen/CHECK_LK_PAGE", {
          page_url: this.newDriverPage.trim(),
        });
        if (lkPageCheck && lkPageCheck.public === true) {
          const newDriver = {
            page_url: this.newDriverPage.trim(),
            page_id: lkPageCheck.id,
            mask: lkPageCheck.mask,
            active: true,
            image: lkPageCheck.image,
            name: lkPageCheck.name,
            type: 'lk',
            added: new Date().valueOf(),
            geo_dashboard: false,
          };
          if (this.allDriversToAdd.some((d) => d.page_id == newDriver.page_id)) {
            this.showWarning("Profile page already added");
          } else {
            this.newDriverPage = "";
            this.$emit("driverToAdd", newDriver);
          }
        } else {
          this.emitError("Server fails to validate a profile page.")
        }
      } catch (error) {        
        console.log("[Error] Linkedin in function [sendUrlCheck], ", error);
        throw error;
      } finally {
        this.buttonDisabled = false;
        this.isBusyTable = false;
      }
    },
  },
};
</script>
<style>
</style>