var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "bv-modal-example",
          staticClass: "main-modal",
          attrs: {
            "no-close-on-backdrop": "",
            size: "lg",
            "hide-footer": "",
            "no-enforce-focus": true
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [_vm._v(" Create context ")]
              },
              proxy: true
            },
            {
              key: "modal-header-close",
              fn: function() {
                return [_c("icon-close-x")]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.model,
            callback: function($$v) {
              _vm.model = $$v
            },
            expression: "model"
          }
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-text" },
            [
              _c("cp-input-text", {
                attrs: {
                  id: "NameContext",
                  placeholder: "Context name",
                  validate: _vm.validateTitle,
                  errorSubject: "Context name"
                },
                on: { "no-errors": _vm.titleError },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "main-context" },
            [
              _c("tabs", {
                attrs: { cant: 5, titles: _vm.tabsSocialNetwork },
                on: { change: _vm.changeTab }
              }),
              _vm._v(" "),
              _vm.redSocialTab === "tw"
                ? _c(
                    "div",
                    [
                      _c("create-twitter-drivers", {
                        attrs: {
                          allDriversToAdd: _vm.allDriversToAdd.filter(function(
                            d
                          ) {
                            return d.type === "tw"
                          }),
                          allIscDriversToAdd: _vm.allIscDriversToAdd.filter(
                            function(d) {
                              return d.type === "tw"
                            }
                          )
                        },
                        on: {
                          driverToAdd: _vm.driverToAdd,
                          deleteDriverToAdd: _vm.deleteDriverToAdd,
                          iscDriverToAdd: _vm.iscDriverToAdd,
                          deleteIscDriverToAdd: _vm.deleteIscDriverToAdd
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.redSocialTab === "fb"
                ? _c(
                    "div",
                    [
                      _c("create-facebook-drivers", {
                        attrs: {
                          allDriversToAdd: _vm.allDriversToAdd.filter(function(
                            d
                          ) {
                            return d.type === "fb"
                          }),
                          allIscDriversToAdd: _vm.allIscDriversToAdd.filter(
                            function(d) {
                              return d.type === "fb"
                            }
                          )
                        },
                        on: {
                          driverToAdd: _vm.driverToAdd,
                          deleteDriverToAdd: _vm.deleteDriverToAdd,
                          iscDriverToAdd: _vm.iscDriverToAdd,
                          deleteIscDriverToAdd: _vm.deleteIscDriverToAdd,
                          "trigger-error": _vm.msgErrorModal
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.redSocialTab === "ig"
                ? _c(
                    "div",
                    [
                      _c("CreateInstagramDrivers", {
                        attrs: {
                          allDriversToAdd: _vm.allDriversToAdd.filter(function(
                            d
                          ) {
                            return d.type === "ig"
                          }),
                          allIscDriversToAdd: _vm.allIscDriversToAdd.filter(
                            function(d) {
                              return d.type === "ig"
                            }
                          )
                        },
                        on: {
                          driverToAdd: _vm.driverToAdd,
                          deleteDriverToAdd: _vm.deleteDriverToAdd,
                          iscDriverToAdd: _vm.iscDriverToAdd,
                          deleteIscDriverToAdd: _vm.deleteIscDriverToAdd,
                          "trigger-error": _vm.msgErrorModal
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.redSocialTab === "lk"
                ? _c(
                    "div",
                    [
                      _c("CreateLinkedinDrivers", {
                        attrs: {
                          allDriversToAdd: _vm.allDriversToAdd.filter(function(
                            d
                          ) {
                            return d.type === "lk"
                          }),
                          allIscDriversToAdd: _vm.allIscDriversToAdd.filter(
                            function(d) {
                              return d.type === "lk"
                            }
                          )
                        },
                        on: {
                          driverToAdd: _vm.driverToAdd,
                          deleteDriverToAdd: _vm.deleteDriverToAdd,
                          iscDriverToAdd: _vm.iscDriverToAdd,
                          deleteIscDriverToAdd: _vm.deleteIscDriverToAdd,
                          "trigger-error": _vm.msgErrorModal
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.redSocialTab === "nws"
                ? _c(
                    "div",
                    [
                      _c("CreateNewsPaperDrivers", {
                        attrs: {
                          allDriversToAdd: _vm.allDriversToAdd.filter(function(
                            d
                          ) {
                            return d.type === "nws"
                          })
                        },
                        on: {
                          driverToAdd: _vm.driverToAdd,
                          deleteDriverToAdd: _vm.deleteDriverToAdd
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-row",
                { staticClass: "button-save" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "text-right textError button-save__warning"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.warningText) +
                          "\n          "
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "button-save__main",
                          class: { buttonDisabled: _vm.buttonDisabled },
                          attrs: {
                            variant: "primary",
                            disabled: _vm.buttonDisabled
                          },
                          on: {
                            click: function($event) {
                              return _vm.saveContext()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "mr-1 button-save__btn" }, [
                            _vm._v(" Save ")
                          ]),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fas fa-save button-save__icon"
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          title: _vm.title,
          paragraph: _vm.paragraph,
          buttonMessage: _vm.buttonMessage,
          noCloseBackdrop: true
        },
        on: { sendResult: _vm.resultModalAlerts },
        model: {
          value: _vm.showAlert,
          callback: function($$v) {
            _vm.showAlert = $$v
          },
          expression: "showAlert"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }