var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          staticClass: "main-modal",
          attrs: {
            "no-close-on-backdrop": "",
            size: "lg",
            "hide-footer": "",
            "no-enforce-focus": true
          },
          on: { close: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [_vm._v(" Update context ")]
              },
              proxy: true
            },
            {
              key: "modal-header-close",
              fn: function() {
                return [_c("icon-close-x")]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.model,
            callback: function($$v) {
              _vm.model = $$v
            },
            expression: "model"
          }
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "text-container" },
            [
              _c(
                "b-col",
                { staticClass: "pl-0", attrs: { cols: "3" } },
                [
                  _c("cp-input-text", {
                    attrs: {
                      id: "NameContext",
                      placeholder: "Context name",
                      validate: _vm.validateTitle,
                      errorSubject: "Context name"
                    },
                    on: { "no-errors": _vm.titleError },
                    model: {
                      value: _vm.contextName,
                      callback: function($$v) {
                        _vm.contextName = $$v
                      },
                      expression: "contextName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "pl-0", attrs: { cols: "9" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "input-text__btn-pencil",
                      class: { buttonDisabled: _vm.buttonDisabled },
                      attrs: {
                        disabled: _vm.buttonDisabled,
                        variant: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.updateNameContext()
                        }
                      }
                    },
                    [
                      _vm.buttonLoading
                        ? _c(
                            "span",
                            [
                              _c("b-spinner", {
                                staticClass: "primary",
                                attrs: { small: "" }
                              })
                            ],
                            1
                          )
                        : _c("span", [
                            _c("i", {
                              staticClass: "fas fa-pencil-alt inactive"
                            })
                          ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "main-context" },
            [
              _c("tabs", {
                attrs: { cant: 5, titles: _vm.tabsSocialNetwork },
                on: { change: _vm.changeTab }
              }),
              _vm._v(" "),
              _vm.redSocialTab === "tw"
                ? _c(
                    "div",
                    [
                      _c("UpdateTwitterDrivers", {
                        attrs: { formContext: _vm.formContext },
                        on: { "trigger-error": _vm.msgErrorModal }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.redSocialTab === "fb"
                ? _c(
                    "div",
                    [
                      _c("update-facebook-drivers", {
                        attrs: { formContext: _vm.formContext },
                        on: { "trigger-error": _vm.msgErrorModal }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.redSocialTab === "ig"
                ? _c(
                    "div",
                    [
                      _c("UpdateInstagramDrivers", {
                        attrs: { formContext: _vm.formContext },
                        on: { "trigger-error": _vm.msgErrorModal }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.redSocialTab === "lk"
                ? _c(
                    "div",
                    [
                      _c("UpdateLinkedinDrivers", {
                        attrs: { formContext: _vm.formContext },
                        on: { "trigger-error": _vm.msgErrorModal }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.redSocialTab === "nws"
                ? _c(
                    "div",
                    [
                      _c("update-news-paper-drivers", {
                        attrs: { formContext: _vm.formContext },
                        on: { "trigger-error": _vm.msgErrorModal }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          title: _vm.title,
          paragraph: _vm.paragraph,
          buttonMessage: _vm.buttonMessage,
          noCloseBackdrop: true
        },
        on: { sendResult: _vm.resultModalAlerts },
        model: {
          value: _vm.showAlert,
          callback: function($$v) {
            _vm.showAlert = $$v
          },
          expression: "showAlert"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }