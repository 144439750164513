import { handlerRespAxiosMonitorLogin } from "../../../../lib/handleResponse";

export default {
  async UPDATE_USER_IMG_PROFILE_CLIENT(_, data) {
    try {
      return await handlerRespAxiosMonitorLogin(
        "post",
        "user/updateImageProfile",
        data
      );
    } catch (error) {
      console.log("[Error] in function: UPDATE_USER_IMG_PROFILE_CLIENT ", error);
      throw error;
    }
  },
  async UPDATE_USER_IMG_PROFILE_USUARIO(_, data) {
    try {
      return await handlerRespAxiosMonitorLogin(
        "post",
        "usuario/update-image-profile",
        data
      );
    } catch (error) {
      console.log("[Error] in function: UPDATE_USER_IMG_PROFILE_USUARIO ", error);
      throw error;
    }
  },
}