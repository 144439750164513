var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("multiselect", {
        staticClass: "multiselect-custom",
        attrs: {
          options: _vm.options,
          disabled: _vm.disabled,
          searchable: _vm.searchable,
          "close-on-select": true,
          "custom-label": _vm.nameWithLang,
          label: "name",
          deselectLabel: _vm.deselectLabel,
          selectLabel: _vm.selectLabel,
          selectedLabel: _vm.selectedLabel,
          trackBy: _vm.trackBy
        },
        on: { select: _vm.selectValue },
        model: {
          value: _vm.valueSelected,
          callback: function($$v) {
            _vm.valueSelected = $$v
          },
          expression: "valueSelected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }