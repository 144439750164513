export const twitterMixin = {
  data() {
    return {
      validateText: {
        required: true,
        maxLength: 40,
        maxLengthRequired: true,
      },
      countrySelected: { // variable TW component
        name: "All (Around the world)",
        id: "all",
      },
      countries: [  // variable TW component
        {
          name: "All (Around the world)",
          id: "all",
        },
        {
          name: "Perú",
          id: "2dfa9ecb0179a4e4",
        },
        {
          name: "Chile",
          id: "47a3cf27863714de",
        },
        /* {
          id: "0639360bd49a15e3",
          name: "Colombia",
        }, */
      ],
    };
  },
  created() {
    
  },
  watch: {
    countrySelected: {
      immediate: true,
      handler: function () {
        this.newDriverCountryId = this.countrySelected.id;
      },
    },
  },
  methods: {},
};