<template>
  <div class="context-box__panel">
      <div class="context-box__panel-text">
        <p class="text-title-context">What:</p>
        <cp-input-text
          v-model="currentTextValue"
          :placeholder="placeholder"
          :validate="validateText"
          @no-errors="errorTerm"
          :errorSubject="errorSubject"
        />
        <span v-if="warningText != ''" class="message-error">
          <i class="fa fa-exclamation-circle"></i> {{ warningText }}</span
        >
      </div>
      <div class="context-box__panel-buttons">
        <b-button
          class=""
          size="md"
          :disabled="buttonDisabled"
          variant="primary"
          @click="$emit('click-create')"
        >
          <span class="context-box__panel-buttons__title">Create</span>
        </b-button>
        <b-button
          v-if="showBtnDrivers"
          size="md"
          class="context-box__panel-buttons__btn-isc"
          @click="openDriverModal = true"
          variant="primary"
        >
          <span class="white--text mr-1">+</span
          ><span class="context-box__panel-buttons__title"> ISC Drivers</span>
        </b-button>
      </div>

      <AddIscDriverModal
        v-model="openDriverModal"
        @closeIscDriverModal="closeIscDriverModal"
        :driverType="driverType"
        :textKeySN="textKeySN"
      />
    </div>
</template>

<script>
import CpInputText from "../../../components/input/CpInputText.vue";
import AddIscDriverModal from "../../modal/AddIscDriverModal.vue";
export default {
  name: 'CpPanelSN',
  components:{
    CpInputText,
    AddIscDriverModal
  },
  props:{
    placeholder:{
      type: String,
      default: ""
    },
    warningText:{
      type: String,
      default: ""
    }, 
    buttonDisabled: {
      type: Boolean,
      default: true
    },
    textValue: {
      type: String,
      default: "",
    },
    showBtnDrivers:{
      type:Boolean,
      default: true
    }, 
    driverType:{
      type: String,
      default: ""
    }, 
    textKeySN:{
      type: String,
      default: "name"
    },
    errorSubject: String
  },
  computed: {
    currentTextValue: {
      get() {
        return this.textValue;
      },
      set(val) {
        this.$emit("update:text-value", val);
      },
    },
  },
  data() {
    return {
      validateText:{
        //required: true,
        urlRequired: true
      }, 
      openDriverModal: false
    };
  },

  methods: {
    errorTerm(errors){
      this.$emit('no-errors', errors);
    },
    closeIscDriverModal(selectedDriver){
      this.openDriverModal = false;
      this.$emit('closeIscDriverModal', selectedDriver)
    },

  },
};
</script>

<style lang="scss" scoped>
  .context-box__panel {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 35px;
    .btn {
      font-size: 14px;
      background: #00a5ff;
    }
    &-text {
      height: 56px;
      width: 444px;
    }
    &-buttons {
      padding-top: 22px;
      width: 176px;
      &__title {
        font-size: 12px;
      }
      &__btn-isc {
        margin-left: 4px;
      }
    }
}
</style>