var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        size: _vm.sizeModal,
        "no-enforce-focus": true,
        "hide-footer": "",
        "no-close-on-backdrop": ""
      },
      on: { close: _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "modal-header-close",
          fn: function() {
            return [_c("icon-close-x")]
          },
          proxy: true
        },
        {
          key: "modal-title",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.title) + " ")]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.model,
        callback: function($$v) {
          _vm.model = $$v
        },
        expression: "model"
      }
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "b-form",
            [
              _vm._l(_vm.formFields, function(field, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "input-group-" + index,
                          label: field.label,
                          "label-for": "input-" + index
                        }
                      },
                      [
                        field.activePassword
                          ? _c(
                              "b-input-group",
                              [
                                _c("cp-input-text", {
                                  staticClass: "main__input-password",
                                  style: { width: _vm.widthInpPwd },
                                  attrs: {
                                    id: "input-" + index,
                                    type: field.type,
                                    validate: field.validate,
                                    errorSubject: field.label
                                  },
                                  on: {
                                    "no-errors": function($event) {
                                      return _vm.setErrors($event, index)
                                    }
                                  },
                                  model: {
                                    value: field.value,
                                    callback: function($$v) {
                                      _vm.$set(field, "value", $$v)
                                    },
                                    expression: "field.value"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-input-group-append",
                                  [
                                    _c(
                                      "b-input-group-text",
                                      {
                                        staticClass:
                                          "main__input-password--container-icon",
                                        on: {
                                          click: function($event) {
                                            return _vm.showPassword(
                                              field.type,
                                              index
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "main__input-password--icon",
                                          class: [
                                            field.type === "password"
                                              ? "fa fa-eye"
                                              : "fa fa-eye-slash"
                                          ]
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : [
                              field.type === "checkbox"
                                ? [
                                    _c("b-form-checkbox", {
                                      attrs: { switch: "" },
                                      model: {
                                        value: field.value,
                                        callback: function($$v) {
                                          _vm.$set(field, "value", $$v)
                                        },
                                        expression: "field.value"
                                      }
                                    })
                                  ]
                                : _c("cp-input-text", {
                                    attrs: {
                                      id: "input-" + index,
                                      type: field.type,
                                      validate: field.validate
                                    },
                                    on: {
                                      "no-errors": function($event) {
                                        return _vm.setErrors($event, index)
                                      }
                                    },
                                    model: {
                                      value: field.value,
                                      callback: function($$v) {
                                        _vm.$set(field, "value", $$v)
                                      },
                                      expression: "field.value"
                                    }
                                  })
                            ]
                      ],
                      2
                    )
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.isErrorGlobal
                ? _c(
                    "b-row",
                    { staticClass: "message-error main__box-error" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("i", { staticClass: "fa fa-exclamation-circle" }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.errorMsgGlobal) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "main__btn-container" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "main__btn-container__btn-save",
                      attrs: { variant: "primary", disabled: _vm.disabledBtn },
                      on: { click: _vm.onSubmit }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.btnText) + "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }