import { handlerChangePassword } from "../../../../lib/handlerRespAws";

export default {
  async UPDATE_USER_PASSWORD(_, data) {
    try {
      const oldPassword = data.oldPassword;
      const newPassword = data.newPassword;
      return await handlerChangePassword(oldPassword, newPassword);
    } catch (error) {
      console.log("[Error] in function: UPDATE_USER_PASSWORD", error);
    }
  }
}