<template>
  <div>
    <b-modal
      no-close-on-backdrop
      v-model="model"
      size="lg"
      ref="bv-modal-example"
      hide-footer
      :no-enforce-focus="true"
      class="main-modal"
    >
      <!-- Slot - modal title -->
      <template #modal-title> Create context </template>
      <template v-slot:modal-header-close>
        <icon-close-x />
      </template>
      <div class="input-text">
        <cp-input-text
          id="NameContext"
          placeholder="Context name"
          v-model="name"
          :validate="validateTitle"
          errorSubject="Context name"
          @no-errors="titleError"
        />
      </div>
      <div class="main-context">
        <tabs :cant="5" :titles="tabsSocialNetwork" @change="changeTab" />
        <div v-if="redSocialTab === 'tw'">
          <create-twitter-drivers
            :allDriversToAdd="allDriversToAdd.filter((d) => d.type === 'tw')"
            @driverToAdd="driverToAdd"
            @deleteDriverToAdd="deleteDriverToAdd"
            :allIscDriversToAdd="
              allIscDriversToAdd.filter((d) => d.type === 'tw')
            "
            @iscDriverToAdd="iscDriverToAdd"
            @deleteIscDriverToAdd="deleteIscDriverToAdd"
          />
        </div>
        <div v-if="redSocialTab === 'fb'">
          <create-facebook-drivers
            :allDriversToAdd="allDriversToAdd.filter((d) => d.type === 'fb')"
            @driverToAdd="driverToAdd"
            @deleteDriverToAdd="deleteDriverToAdd"
            :allIscDriversToAdd="
              allIscDriversToAdd.filter((d) => d.type === 'fb')
            "
            @iscDriverToAdd="iscDriverToAdd"
            @deleteIscDriverToAdd="deleteIscDriverToAdd"
            @trigger-error="msgErrorModal"
          />
        </div>
        <div v-if="redSocialTab === 'ig'">
          <CreateInstagramDrivers
            :allDriversToAdd="allDriversToAdd.filter((d) => d.type === 'ig')"
            @driverToAdd="driverToAdd"
            @deleteDriverToAdd="deleteDriverToAdd"
            :allIscDriversToAdd="
              allIscDriversToAdd.filter((d) => d.type === 'ig')
            "
            @iscDriverToAdd="iscDriverToAdd"
            @deleteIscDriverToAdd="deleteIscDriverToAdd"
            @trigger-error="msgErrorModal"
          />
        </div>
        <div v-if="redSocialTab === 'lk'">
          <CreateLinkedinDrivers
            :allDriversToAdd="allDriversToAdd.filter((d) => d.type === 'lk')"
            @driverToAdd="driverToAdd"
            @deleteDriverToAdd="deleteDriverToAdd"
            :allIscDriversToAdd="
              allIscDriversToAdd.filter((d) => d.type === 'lk')
            "
            @iscDriverToAdd="iscDriverToAdd"
            @deleteIscDriverToAdd="deleteIscDriverToAdd"
            @trigger-error="msgErrorModal"
          />
        </div>
        <div v-if="redSocialTab === 'nws'">
          <CreateNewsPaperDrivers
            :allDriversToAdd="allDriversToAdd.filter((d) => d.type === 'nws')"
            @driverToAdd="driverToAdd"
            @deleteDriverToAdd="deleteDriverToAdd"
          />
        </div>
  
        <b-row class="button-save">
          <b-col class="text-right textError button-save__warning">
            {{ warningText }}
            <b-button
              class="button-save__main"
              variant="primary"
              v-bind:class="{ buttonDisabled }"
              @click="saveContext()"
              :disabled="buttonDisabled"
            >
            <span class="mr-1 button-save__btn"> Save </span>
              <!-- Icon diskette -->
              <i class="fas fa-save button-save__icon"></i>
              <!--  <span class="mr-1 button-save__btn">{{
                loading ? "Saving..." : "Save"
              }}</span>
              <i v-if="!loading" class="fas fa-save button-save__icon"></i> -->
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <!-- MODAL ALERT GLOBAL -->
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="resultModalAlerts"
      :noCloseBackdrop="true"
    >
    </IscModalAlerts>
  </div>
</template>

<script>
import Tabs from "../../Tabs.vue";
import IconCloseX from "../../components/icon/closeX.vue";
import CreateTwitterDrivers from "../context/CreateTwitterDrivers.vue";
import CreateFacebookDrivers from "../context/CreateFacebookDrivers.vue";
import CreateInstagramDrivers from "../context/CreateInstagramDrivers.vue";
import CreateLinkedinDrivers from "../context/CreateLinkedinDrivers.vue";
import CreateNewsPaperDrivers from "../context/CreateNewspaperDrivers.vue";
import CpInputText from "../../components/input/CpInputText.vue";
import { getObjUserSession } from "../../../lib/helpers";
import{ mapGetters } from "vuex";
import { contextMixin, alertSetting } from "../../../mixins/index"
import IscModalAlerts from "../../modal/IscModalAlerts.vue"
export default {
  name: "IscCreateContext",
  components: {
    Tabs,
    CreateTwitterDrivers,
    CreateFacebookDrivers,
    CreateInstagramDrivers,
    CreateLinkedinDrivers,
    CreateNewsPaperDrivers,
    CpInputText,
    IconCloseX,
    IscModalAlerts
  },
  mixins: [ contextMixin, alertSetting ],
  props: {
    isSavedInMemory: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getAddedIscDrivers: "settings/listen/allAddedIscDrivers",
      getAddedDrivers: "settings/listen/allAddedDrivers",
      getNameContext: "settings/listen/getNameContext",
    }),
  },
  data() {
    return {
      name: "",
      actionContext: "",
      warningText: "",
      //loading: false,
      ISCDrivers: [],
      buttonDisabled: true,
      twDriversToAdd: [],
      fbDriversToAdd: [],
      igDriversToAdd: [],
      lkDriversToAdd: [],
      nwsDriversToAdd: [],

      allIscDriversToAdd: [],
      allDriversToAdd: [],
      validateTitle: {
        required: true,
      },
      titleErrors: [],
    };
  },

  mounted() {
    this.setDriversForSavingMemory();
  },

  methods: {
    msgErrorModal(text){
      this.errorModal(text);
    },
    driverToAdd(driver) {
      this.allDriversToAdd.push(driver);
    },
    iscDriverToAdd(driver) {
      this.allIscDriversToAdd.push(driver);
    },
    deleteIscDriverToAdd(addedDriver, attr) {
      this.allIscDriversToAdd = this.allIscDriversToAdd.filter(
        (item) => item[attr] !== addedDriver
      );
      this.validateInputs();
    },
    deleteDriverToAdd(addedDriver, attr) {
      this.allDriversToAdd = this.allDriversToAdd.filter(
        (item) => item[attr] !== addedDriver
      );
      this.validateInputs();
    },
    saveContext: async function () {
      if (this.name.length <= 0) return;
      if (
        this.allDriversToAdd.length > 0 ||
        this.allIscDriversToAdd.length > 0
      ) {
        const tw_drivers_array = this.allDriversToAdd
          .filter((d) => d.type === "tw")
          .map((driver) => {
            return {
              active: driver.active,
              place_id: driver.place_id,
              term: driver.term,
            };
          });
        const fb_drivers_array = this.allDriversToAdd
          .filter((d) => d.type === "fb")
          .map((driver) => {
            return {
              active: driver.active,
              mask: driver.mask,
              name: driver.name,
              page_id: driver.page_id,
              page_url: driver.page_url,
            };
          });
        const ig_drivers_array = this.allDriversToAdd
          .filter((d) => d.type === "ig")
          .map((driver) => {
            return {
              active: driver.active,
              mask: driver.mask,
              name: driver.name,
              page_id: driver.page_id,
              page_url: driver.page_url,
            };
          });
        const lk_drivers_array = this.allDriversToAdd
          .filter((d) => d.type === "lk")
          .map((driver) => {
            return {
              active: driver.active,
              mask: driver.mask,
              name: driver.name,
              page_id: driver.page_id,
              page_url: driver.page_url,
            };
          });
        const nws_drivers_array = this.allDriversToAdd
          .filter((d) => d.type === "nws")
          .map((driver) => {
            return {
              id: driver.id,
              active: driver.active,
            };
          });
        const variablesCreateContext = {
          account_id: getObjUserSession().id_cliente.toString(),
          name: this.name,
          twitter_drivers: tw_drivers_array.length ? tw_drivers_array : null,
          facebook_drivers: fb_drivers_array.length ? fb_drivers_array : null,
          instagram_drivers: ig_drivers_array.length ? ig_drivers_array : null,
          linkedin_drivers: lk_drivers_array.length ? lk_drivers_array : null,
          newspapers_drivers: nws_drivers_array.length
            ? nws_drivers_array
            : null,
        };
        this.$emit(
          "context-isc-drivers",
          variablesCreateContext,
          this.allIscDriversToAdd
        );
        //*
        if(!this.isSavedInMemory) return
        this.$store.commit("settings/listen/SET_ALL_ADDED_DRIVERS", this.allIscDriversToAdd);
        this.$store.commit("settings/listen/SET_ADDED_DRIVERS", this.allDriversToAdd );
        this.$store.commit("settings/listen/SET_NAME_CONTEXT", this.name );
        // Code to save  a context into listen
        // Main Method to save
        /* try {
          const {
            data: { createContext },
          } = await this.$store.dispatch(
            "overview/createContext",
            variablesCreateContext
          );
          if (createContext.id) {
          if (this.allIscDriversToAdd.length > 0) {
            for (const iscDriver of this.allIscDriversToAdd) {
              await this.$store.dispatch("overview/addGeoKeyword", {
                  context_id: createContext.id,
                  keyword_id: iscDriver.id,
                  active: iscDriver.active,
                  type: iscDriver.type,
                });
            }
          }
          } else {
            throw new Error("Error => createContext dispatch");
          }
          this.loading = false;
          this.$emit("close", createContext);
        } catch (error) {
          console.error(error);
          this.showWarning("Something went wrong!");
          return;
        } */
      }
    },
    //* this function only works for saving in memory
    setDriversForSavingMemory(){
      if(!this.isSavedInMemory) return;
      if(this.getAddedIscDrivers.length > 0)
        this.allIscDriversToAdd = this.getAddedIscDrivers;
      if(this.getAddedDrivers.length > 0)
        this.allDriversToAdd = this.getAddedDrivers;
      if(this.getNameContext)
        this.name = this.getNameContext;
    },
    deleteIscDriversToAdd(driver) {
      const driverIndex = this.allIscDriversToAdd.findIndex((d) => {
        return (
          d.context_id == driver.context_id &&
          d.keyword_id == driver.keyword_id &&
          d.type == driver.type
        );
      });
      this.allIscDriversToAdd.splice(driverIndex, 1);
      this.validateInputs();
    },
    validateInputs() {
      if (
        this.allDriversToAdd.length > 0 ||
        this.allIscDriversToAdd.length > 0
      ) {
        if (this.name.trim().length > 0 && this.titleErrors.length === 0) {
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;
        }
      } else {
        this.buttonDisabled = true;
      }
    },
    //Error inputs
    showWarning: function (message) {
      this.warningText = message;
    },
    titleError(errors) {
      this.titleErrors = errors;
    },
  },
  watch: {
    allDriversToAdd: {
      immediate: true,
      deep: true,
      handler: function () {
        this.validateInputs();
      },
    },
    allIscDriversToAdd: {
      immediate: true,
      deep: true,
      handler: function () {
        this.validateInputs();
      },
    },
    name: {
      immediate: true,
      handler: function () {
        if (!this.name) this.showWarning("Please enter a context name");
        else this.showWarning("");
        this.validateInputs();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.button-save {
  &__icon {
    font-size: 13px;
    color: #fff;
  }
  &__warning {
    font: 12px Oxygen;
    color: red;
  }
  &__btn {
    font: 13px Oxygen;
  }
  &__main {
    margin-left: 5px;
  }
}
::v-deep .modal-body {
  padding: 12px 20px;
}
.main-context {
  padding: 8px 0px;
  height: 100%;
}
.input-text {
  padding: 8px 0px;
  width: 166px;
}
</style>
