<template>
  <div>
    <b-tooltip
      :target="target"
      :title="title"
      triggers="hover"
      custom-class="tooltip-icon"
      variant="light"
      :placement="placement"
    >
      <div class="tooltip-icon-title">
        <i class="fa fa-circle" :style="{color: colorIcon}"></i>
        <span>{{ messageTitle }}</span>
      </div>
      <div class="tooltip-icon-subtitle">{{ messageSubTitle }}</div>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: "CpTooltipIconCustom",
  data() {
    return {};
  },
  props: {
    target: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    messageTitle: {
      type: String,
      default: "",
    },
    messageSubTitle: {
      type: String,
      default: "",
    },
    colorIcon: {
      type: String,
      default: "",
    },
    placement: {
      type: String,
      default: "top"
    }
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
  .tooltip-icon{
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(107, 115, 139, 0.2);
    border-radius: 6px;
    width: 190px;
    height: 80px;
  }
  ::v-deep .tooltip-inner{
    padding: 0px;
  }
  ::v-deep .arrow{
    top: 80px;
  }
  .tooltip-icon-title{
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;
    padding-top: 11px;
    background: #FFFFFF;
    color: #6B738B;
    padding-bottom: 11px;
  }
  .tooltip-icon-subtitle {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    background: #FFFFFF;
    color: #6B738B;
  }
  .fa-circle{
    font-size: 12px;
  }

</style>
