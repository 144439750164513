var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tooltip",
        {
          attrs: {
            target: _vm.target,
            title: _vm.tooltipTitle,
            triggers: "hover",
            "custom-class": "tooltipDouble",
            variant: "light"
          }
        },
        _vm._l(_vm.columns, function(item, key) {
          return _c("div", { key: key, staticClass: "containerColumn" }, [
            _c("div", { staticClass: "header flex-center" }, [
              _c("p", [_vm._v(_vm._s(item.header))])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "container-body d-flex flex-center" }, [
              _c("div", { staticClass: "body" }, [
                item.body.facebook.value
                  ? _c("div", { staticClass: "d-flex align-item-center" }, [
                      _c("div", [
                        _c("i", {
                          staticClass: "fab fa-facebook i-social-networks"
                        })
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", { staticClass: "p-strong" }, [
                          _vm._v("Facebook: ")
                        ]),
                        _vm._v(
                          _vm._s(item.body.facebook.message) + "\n            "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                item.body.instagram.value
                  ? _c("div", { staticClass: "d-flex align-item-center" }, [
                      _c("div", [
                        _c("i", {
                          staticClass: "fab fa-instagram i-social-networks"
                        })
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", { staticClass: "p-strong" }, [
                          _vm._v("Instagram:")
                        ]),
                        _vm._v(
                          "\n              " +
                            _vm._s(item.body.instagram.message) +
                            "\n            "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                item.body.linkedin.value
                  ? _c("div", { staticClass: "d-flex align-item-center" }, [
                      _c("div", [
                        _c("i", {
                          staticClass: "fab fa-linkedin i-social-networks"
                        })
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", { staticClass: "p-strong" }, [
                          _vm._v("LinkedIn:")
                        ]),
                        _vm._v(
                          "\n              " +
                            _vm._s(item.body.linkedin.message) +
                            "\n            "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                item.body.twitter.value
                  ? _c("div", { staticClass: "d-flex align-item-center" }, [
                      _c("div", [
                        _c("i", {
                          staticClass: "fab fa-twitter i-social-networks"
                        })
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", { staticClass: "p-strong" }, [
                          _vm._v("Twitter:")
                        ]),
                        _vm._v(
                          "\n              " +
                            _vm._s(item.body.twitter.message) +
                            "\n            "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                item.body.youtube.value
                  ? _c("div", { staticClass: "d-flex align-item-center" }, [
                      _c("div", [
                        _c("i", {
                          staticClass: "fab fa-youtube i-social-networks"
                        })
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", { staticClass: "p-strong" }, [
                          _vm._v("YouTube:")
                        ]),
                        _vm._v(
                          "\n              " +
                            _vm._s(item.body.youtube.message) +
                            "\n            "
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }