<template>
  <div>
    <b-tooltip
      :target="target"
      :title="title"
      triggers="hover"
      custom-class="tooltipWhite"
      variant="light"
      :placement="placement"
    >
      <p :style="{padding: paddingContainer }" >{{ message }}</p>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: "CpTooltipWhiteCustom",
  data() {
    return {};
  },
  props: {
    target: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    placement: {
      type: String,
      default: "top"
    },
    paddingContainer:{
      type: String, 
      default:"23px 12px 16px 10px"
    }
  },
  methods: {},
  created() {},
};
</script>

<style lang="css" scoped>
.tooltipWhite p{
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #6b738b;
  width: 190px;
  height: 80px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 6px rgba(107, 115, 139, 0.2);
  border-radius: 6px;
}
::v-deep .tooltip-inner{
  padding: 0px;
}
::v-deep .arrow::before {
    border-top-color: #FFFFFF !important;
}
</style>
