
export default {
  SET_GEO_DRIVERS: (state, geoDrivers) => {
    state.geoDrivers = geoDrivers;
  },
  SET_GEO_DRIVERS_FB: (state, geoDriversFb) => {
    state.geoDriversFb = geoDriversFb;
  },  
  SET_GEO_DRIVERS_TW: (state, geoDriversTw) => {
    state.geoDriversTw = geoDriversTw;
  },
  SET_GEO_DRIVERS_IG(state, geoDriversIg) {
    state.geoDriversIg = geoDriversIg;
  },
  SET_GEO_DRIVERS_LK(state, geoDriversLk) {
    state.geoDriversLk = geoDriversLk;
  },  
  SET_ALL_ADDED_DRIVERS(state, allAddedIscDrivers) {
    state.allAddedIscDrivers = allAddedIscDrivers;
  },  
  SET_ADDED_DRIVERS(state, allAddedDrivers) {
    state.allAddedDrivers = allAddedDrivers;
  },  
  SET_NAME_CONTEXT(state, nameContext) {
    state.nameContext = nameContext;
  },  
  SET_GLOBAL_VAR_CONTEXT(state, globalVarContext) {
    state.globalVarContext = globalVarContext;
  },  
  SET_GLOBAL_ISC_DRIVERS(state, globalIscDrivers) {
    state.globalIscDrivers = globalIscDrivers;
  },  
};
