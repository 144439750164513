<template>
  <div>
      <multiselect
        class="multiselect-custom"
        v-model="valueSelected"
        :options="options"
        :disabled="disabled"
        :searchable="searchable"
        :close-on-select="true"
        :custom-label="nameWithLang"
        label="name"
        @select="selectValue"
        :deselectLabel="deselectLabel"
        :selectLabel="selectLabel"
        :selectedLabel="selectedLabel"
        :trackBy="trackBy"
      >
      </multiselect>
  </div>
</template>
  
<script>
import Multiselect from 'vue-multiselect'
export default {
  name: "MultiSelectCustom",
  components: {
    Multiselect
  },
  props: {
    selected: {
      type: [Object, String],
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    deselectLabel: {
      type: String,
      default: ""
    },
    selectLabel: {
      type: String,
      default: ""
    },
    selectedLabel: {
      type: String,
      default: ""
    },
    trackBy: {
      type: String,
      default: "value"
    },
  },
  data() {
    return {
      valueSelected: this.selected,
    };
  },
  watch: {
    selected(value){
      this.valueSelected = value
    }
  },
  methods: {
    selectValue(change) {
      this.$emit("selectValue", change);
    },
    nameWithLang ({ text }) {
      return `${text}`
    }
  },
};
</script>
<style lang="scss" scoped>
.custom-select {
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40'  d='M2 0L0 1zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px !important;
}
.multiselect-custom{
  ::v-deep .multiselect__tags {
    border-radius: 3px;
    border: solid 1px #C5D0E4;
    box-shadow: 0px 2px 5px 0px rgba(197, 208, 228, 0.62) inset;
  }
  ::v-deep .multiselect__content-wrapper {
    font-size: 12px;
    font-family: Oxygen;
    font-weight: normal;
    color: #5d6b88;
  }
  ::v-deep .multiselect__single{
    color: #5d6b88;;
  }
  ::v-deep .multiselect__option--selected{
    background: #fff;
  }
  ::v-deep .multiselect__select:before {
    color: #5D6B88;
    border-color: #5D6B88 transparent transparent;
  }
}
/* 
@media (max-width: 1400px) {
  ::v-deep .selectCustom {
    width: 210px !important;
    .multiselect__content-wrapper{
      width: 208px !important;
    }
  }
}

@media (max-width: 1250px) {
  ::v-deep .selectCustom {
    width: 170px !important;
    .multiselect__content-wrapper{
      width: 168px !important;
    }
  }
}

@media (max-width: 1150px) {
  ::v-deep .selectCustom {
    width: 140px !important;
    .multiselect__content-wrapper{
      width: 139px !important;
    }
  }
} */
</style>
  