var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "breadcrumb-c d-flex justify-content-between align-items-center"
    },
    [
      _c(
        "div",
        _vm._l(_vm.levelsActive, function(level, index) {
          return _c("span", { key: index, staticClass: "text-capitalize" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.replacenames(level, _vm.levelsActive[index - 1])) +
                "\n      "
            ),
            _c("span", { staticClass: "mx-1" }, [_vm._v("/")])
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }