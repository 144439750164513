<template>
  <div class="context-box">
    <div class="context-box__text">
      <p class="text-title-context">What:</p>
    </div>
    <b-row class="context-box__panel">
      <b-col cols="3">
        <p class="text-title-context">Country:</p>
        <cp-simple-select-custom
          v-model="nwsCountrySelected"
          :options="countries"
          :disabled="allNewspapers.length == 0"
          @input="changeSelectPlace"
        />
      </b-col>
      <b-col cols="">
        <p class="text-title-context">Newspaper:</p>
        <cp-simple-select-custom
          v-model="nwsNameSelected"
          :options="nwsNames"
          :disabled="!nwsCountrySelected"
          @input="changeSelectName"
        />
      </b-col>
      <b-col cols="4">
        <p class="text-title-context">Section:</p>
        <cp-simple-select-custom
          v-model="nwsUrlSelected"
          :options="nwsUrls"
          :disabled="!nwsNameSelected"
          @input="changeSelectUrl"
        />
      </b-col>
      <b-col cols="1" class="context-box__panel-buttons">
        <b-button
          class=""
          size="md"
          :disabled="!nwsToAdd"
          variant="primary"
          @click="addNewDriver()"
        >
          <span class="context-box__panel-buttons__title">Add</span>
        </b-button>
      </b-col>
    </b-row>
    <span v-if="warningText != ''" class="message-error">
      <i class="fa fa-exclamation-circle"></i> {{ warningText }}</span
    >
    <!-- TABLE List add items    -->
    <b-row class="context-box__table">
      <b-col>
        <div class="context-box__table__body">
          <cp-table-driver
            :items="formContext.newspaperKeywords"
            @delete-item="deleteDriver"
            @update-toggle-active="toggleActive"
            :fields="fields"
            title="Profile Page List"
            :isBusy="isBusyTable"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CpTableDriver from "../../components/table/CpTableDriver.vue";
import CpSimpleSelectCustom from "../../components/select/CpSimpleSelectCustom.vue";
import { nwsPaperMixin, socialNetwork } from "../../../mixins/index";
export default {
  name: "UpdateNewsPaperDrivers",
  components: { CpSimpleSelectCustom, CpTableDriver },
  mixins: [nwsPaperMixin, socialNetwork],
  props: ["formContext"],
  data() {
    return {
      nwsCountries: [],
    };
  },
  methods: {
    async toggleActive(id, status) {
      this.isBusyTable = true;
      if (id) {
        const variables = {
          context_id: this.formContext.contextId,
          keyword_id: id,
          type: "nws",
          active: status,
        };
        try {
          await this.setActiveToggleKeyword(variables);
          /* if (toggleActiveKeyword[0].active === status) {
            this.formContext.newspaperKeywords = this.formContext.newspaperKeywords.map(function (k) {
              if (k.id == id) {
                k.active = status;
              }
              return k;
            });
          } */
        } catch (error) {
          // If there is an error, get back from old formContext.newspaperKeywords
          this.formContext.newspaperKeywords = this.formContext.newspaperKeywords.map(
            function (k) {
              if (k.id === id) {
                k.active = !status;
              }
              return k;
            }
          );
          console.error("[Error] in function toggleActive(NwsPaper) ", error);
          this.emitError("There was an error to change a keyword'status.")
        } finally {
          this.isBusyTable = false;
        }
      }
    },
    async addNewDriver() {
      try {
        if (!this.nwsToAdd) return;
        this.isBusyTable = true;
        const driverToAdd = Object.assign({}, this.nwsToAdd);
        this.cleanSelects();
        const body = {
          context_id: this.formContext.contextId,
          type: "nws",
          active: true,
          driver: {
            id: driverToAdd.id,
          },
        };
        if (this.formContext.newspaperKeywords.length > 0) {
          const driverExist = this.formContext.newspaperKeywords.find(
            (d) => d.id === driverToAdd.id
          );
          if (driverExist) {
            this.showWarning("Newspaper already added.");
            this.$emit("updateLoading", false);
            return;
          }
        }
        const {
          data: { addKeyword },
        } = await this.addKeywordListen(body);

        if (addKeyword.id) {
          this.formContext.newspaperKeywords.push(addKeyword);
        } else {
          this.emitError("Server fails to add a Newspaper.");
        }
      } catch (error) {
        console.error("[Error] in function addNewDriver", error);
        this.mainProcessErrorGraphQl();
      } finally {
        this.isBusyTable = false;
      }
    },
    async deleteDriver(driver) {
      if (this.getAllKeywordsLength() > 1 && driver.id) {
        this.isBusyTable = true;
        const driverIndex = this.formContext.newspaperKeywords.findIndex(
          (d) => {
            return d.id === driver.id;
          }
        );
        this.formContext.newspaperKeywords.splice(driverIndex, 1);
        const variables = {
          context_id: this.formContext.contextId,
          keyword_id: driver.id,
          type: "nws",
        };
        try {
          const {
            data: { deleteKeyword },
          } = await this.deleteKeywordListen(variables);

          if (deleteKeyword != "keyword deleted") {
            this.formContext.newspaperKeywords.splice(driverIndex, 1, driver);
            this.emitError("Server fails to delete a driver NewsPaper.");
          }
        } catch (error) {
          this.formContext.newspaperKeywords.splice(driverIndex, 1, driver);
          console.error(
            "[Error] in function  deleteDriver(nwsPaper) ",
            error
          );
          this.mainProcessErrorGraphQl();
        } finally {
          this.isBusyTable = false;
        }
        
      } else {
        this.emitError("At least you must have one driver in your context.")
      }
    },
  },
  /* watch: {
    formContext: {
      immediate: true,
      handler: function (x) {
        this.formContext.newspaperKeywords = this.formContext.newspaperKeywords;
        // if (this.newspaperAdd.trim().length > 0) {
        //   this.buttonDisabled = false;
        // } else {
        //   this.buttonDisabled = true;
        // }
      },
    },
  }, */
};
</script>
<style lang="scss" , scoped>
.context-box {
  padding: 12px 55px;
  &__text {
    padding-bottom: 3px;
  }
  .btn {
    font-size: 14px;
    background: #00a5ff;
  }
  &__panel {
    margin-top: 2px;
    &-buttons {
      padding-top: 22px;
      width: 95px;
      padding-left: 17px;
      &__title {
        font-size: 12px;
      }
      &__btn-isc {
        margin-left: 4px;
      }
    }
  }
  &__table {
    margin-top: 21px;
    padding: 12px 34px;
  }
}
</style>
