export default {
  SET_DATA_DRIVERS: (
    { commit },
    { dataFacebook, dataTwitter, socialNetwork }
  ) => {
    let respGeoDrivers = "";
    switch (socialNetwork) {
      case "facebook":
        respGeoDrivers = dataFacebook;
        break;
      case "twitter":
        respGeoDrivers = dataTwitter;
        break;
    }
    commit("SET_DATA_GEO_DRIVERS", respGeoDrivers);
  },
  RESET_DATA_DRIVERS: ({ commit }) => {
    commit("RESET_GEO_DRIVERS");
  },
  SET_CHANGE_DATE_RANGE_HEADER: ({ commit }, changeDateRangeHeader) => {
    commit("SET_CHANGE_DATE_RANGE_HEADER", changeDateRangeHeader);
  }
  /* SELECTED_IMAGE_LOGO: ({ commit, state }, image) => {
    const message = state.message;
    message.logoPreview = image;
    message.logo = image.location;
    commit("SET_MESSAGE_ITEMS", message);
  } */
};
