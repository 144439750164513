import { Amplify, Auth } from "aws-amplify";
import AwsConfig from "./aws-exports";
Amplify.configure(AwsConfig);

 export async function handlerChangePassword (oldPassword, newPassword) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const data = await Auth.changePassword(user, oldPassword, newPassword);
    return data;
  } catch(err) {
    console.log("[Error] in function changePassword AWS: ", err);
  }
};