var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "context-box-sn" },
    [
      _c("cp-panel-s-n", {
        attrs: {
          driverType: "fb",
          "text-value": _vm.newDriverPageSN,
          warningText: _vm.warningText,
          buttonDisabled: _vm.buttonDisabled,
          errorSubject: "URL",
          showBtnDrivers: _vm.showBtnDrivers,
          placeholder: "Paste Url Fan Page..."
        },
        on: {
          "update:textValue": function($event) {
            _vm.newDriverPageSN = $event
          },
          "update:text-value": function($event) {
            _vm.newDriverPageSN = $event
          },
          "no-errors": _vm.errorTerm,
          closeIscDriverModal: _vm.closeIscDriverModal,
          "click-create": _vm.addNewDriver
        }
      }),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "context-box-sn__table" },
        [
          _c("b-col", [
            _c(
              "div",
              { staticClass: "context-box-sn__table__body" },
              [
                _c("cp-table-driver", {
                  attrs: {
                    items: _vm.formContext.facebookKeywords,
                    fields: _vm.fields,
                    title: "Fan Page List",
                    isBusy: _vm.isBusyTable
                  },
                  on: {
                    "delete-item": _vm.deleteKeyword,
                    "update-toggle-active": _vm.toggleActive
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }