<template>
  <div class="main-v-select">
    <v-select
      :options="options"
      v-model="model"
      :textProp="textKey"
      :valueProp="valueKey"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import VSelect from "@alfsnd/vue-bootstrap-select";
export default {
  name: "CpSimpleSelectCustom",
  components: {
    VSelect,
  },
  props: {
    options: {
      type: Array,
      default: [],
    },
    valueKey: {
      type: String,
      default: "id",
    },
    textKey: {
      type: String,
      default: "name",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.main-v-select {
  ::v-deep .v-select-toggle {
    height: 35px;
    font-family: Oxygen;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #5d6b88;
    border-radius: 3px;
    border: solid 1px #C5D0E4;
    box-shadow: 0px 2px 5px 0px rgba(197, 208, 228, 0.62) inset;
    background-color: #fff;
  }
  ::v-deep .v-select-toggle div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  ::v-deep .v-select-toggle:hover {
    background-color: #fff;
    color: #5d6b88;
  }
  /* Dropdown box */
  ::v-deep .v-dropdown-item.selected {
    background-color: #fff;
    color: #5d6b88;
  }
  ::v-deep .v-dropdown-item.selected:hover {
    background-color: #eef8ff;
    color: #5d6b88;
  }
  ::v-deep .v-dropdown-item:hover:not(.default-option) {
    background-color: #eef8ff;
    color: #5d6b88;
  }
  ::v-deep .arrow-down {
    border-top: 0.45em solid;
    border-right: 0.45em solid transparent;

    border-left: 0.45em solid transparent;
  }
  ::v-deep .v-dropdown-container {
    overflow-y: auto;
    max-height: 160px;
  }
  ::v-deep .v-dropdown-item {
    line-height: 1.5;
    height: 35px;
  }
}
</style>
