export default {
  profileAccount: [],
  audienceDetails: {
      
      acumulatedAudienceGraph: [],
      audienceCount: [],
      chartCategories: [],
      newAudienceGraph: [],
  },
  postsDetails: [
      {
          chartCategories: [],
          mostUsedDays: [],
          mostUsedHours: [],
          postPerDayGraph: [],
          postsGraph: [],
          totalPostByRrss: [],
      }
  ],
  engageDetails: [
      {
          chartCategories: [],
          dayliEngage: [],
          levels: {},
          typeEngage: {},
          usersEngageByDay: [],
      }
  ],
  interactionsDetail: [
      {
          chartCategories: [],
          interactionsByDay: [],
          interactionsByType: [],
          interactionsByweek: [],
          posts: [],
          socialsTotal: [],
          total: 0,
          typesTotal: [],
      }
  ],
  
}
