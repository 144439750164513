<template>
  <div>
    <!-- title table  -->
    <div class="text-title">
      <span class="text-title-context">{{ title }}</span>
    </div>
    <b-table 
      class="table-driver"
      sticky-header 
      :fields="fields" 
      :items="items" 
      :thead-class="theadClass"
      :busy="isBusy"
    >
    <!-- Commenting this because it does not work anymore. -->
    <!-- <template v-slot:cell(activeTwitter)="data">
      <i
          v-if="data.item.tw_active === true && !data.item.geo_dashboard"
          class="fa fa-circle activeTwitter"
          @click="toggleActiveTwitter(data.item.id, false);"
        >
        </i>
        <i
          v-if="data.item.tw_active === false && !data.item.geo_dashboard"
          class="fa fa-circle inactive"
          @click="toggleActiveTwitter(data.item.id, true);"
        >
        </i>
      </template> -->

      <template v-slot:cell(delete)="data">
        <i class="fas fa-trash delete-action" @click="deleteItem(data.item)"></i>
      </template>
      
      <template  v-slot:cell(active)="data">
        <i
          v-if="data.item.active === true"
          class="fa fa-eye active"
          @click="data.item.active = false; toggleActive(data.item.id, false);"
        >
        </i>
        <i
          v-if="data.item.active === false"
          class="fa fa-eye inactive"
          @click="data.item.active = true; toggleActive(data.item.id, true);"
        >
        </i>
      </template>
      <!-- Section Busy -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "CpTableDriver",
  props: {
    fields: {
      type: Array,
      default: [],
    },
    items: {
      type: Array,
      default: [],
    },
    title: {
      type: String,
      default: ""
    },
    theadClass: {
      type: String,
      default: "d-none"
    },
    isBusy: {
      type:Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    deleteItem: function (item) {
      this.$emit("delete-item", item);
    },
    //* id = item id of saved keyword
    //* value = Boolean  
    toggleActive(id, value){
      this.$emit('update-toggle-active', id, value);
    },
    //* this uncomment if the functions is required again 
    /* toggleActiveTwitter(id, value){
      console.log("Values ", id, value);
      this.$emit('update-toggle-active-tw', id, value);
    } */
  },
};
</script>

<style lang="scss" scoped>

::v-deep .text-row-01 {
  color: #2c3852;
}
::v-deep .text-row-02{
  color:#a2acc4
}
.table-driver {
  font: 12px "Oxygen";
}
.text-title {
  margin-bottom: 15px;
}
.delete-action {
  cursor: pointer;
  color: rgb(162, 172, 196);;
}
.active {
  color: #00a5ff;
  cursor: pointer;
}
.activeTwitter {
  color: rgb(129, 199, 132);
  cursor: pointer;

}
.inactive {
  cursor: pointer;
  color: #a2acc4;
}
</style>
