export default {
  getProfileAccount: (state) => {
    return state.profileAccount;
  },
  getAudienceDetails: (state) => {
    return state.audienceDetails;
  },
  getPostsDetails: (state) => {
    return state.postsDetails;
  },
  getEngagedDetails: (state) => {
    return state.engageDetails;
  },
  getInteractionsDetails: (state) => {
    return state.interactionsDetail;
  },
};
