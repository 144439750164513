<template>
  <div>
    <b-modal
        no-close-on-backdrop
        v-model="model"
        header-class="border-0 pr-5"
        header-close-variant="primary"
        hide-footer
        :no-enforce-focus="true"
      >
      <template v-slot:modal-header-close>
        <icon-close-x />
      </template>
      <div class="containerIscModal">
        <p class="pMessage" v-html="message"></p>
        <div class="d-flex containerButtonModal">
          <b-button variant="primary" class="px-3 style-button" @click="sendResult"
            >Ok</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import IconCloseX from "../../component-library/components/icon/closeX.vue";
export default {
  name: "IscModal",
  components: {
    IconCloseX
  },
  props: {
    message: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    sendResult() {
      this.$emit("sendResult", true);
    },
  },
};
</script>

<style scoped>
.pMessage {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #2c3852;
  padding: 0rem 4rem 0rem 4rem;
}
.containerButtonModal {
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0rem 1.5rem 0rem;
}
.style-button {
  font-size: 14px;
  font-weight: 700px;
}
::v-deep .modal-header {
  padding: 0.5rem 1rem 0rem 1rem !important
}

::v-deep .modal-body{
  padding: 0rem;
}

::v-deep .modal-content{
  width: 37rem;
}
</style>
