<template>
  <b-modal
    no-close-on-backdrop
    v-model="model"
    size="md"
    hide-footer
    :no-enforce-focus="true"
    class="main-modal"
  >
    <!-- Slot - modal title -->
    <template v-slot:modal-title> Add ISC Drivers </template>
    <template v-slot:modal-header-close>
      <icon-close-x />
    </template>
    <div v-if="!loading">
      <div class="pt-3">
        <span class="text-title-context">Select Country</span>
        <cp-simple-select-custom
          v-model="selectedCountry"
          :options="ISCDrivers"
          valueKey="place_id"
          textKey="name"
          @input="selectedIndustry = {}"
        />
      </div>
      <div class="pt-3">
        <span class="text-title-context">Select Industry</span>
        <cp-simple-select-custom
          v-model="selectedIndustry"
          :options="selectedCountry.industries || []"
          valueKey=""
          textKey="name"
          :disabled="!selectedCountry.industries"
          @input="selectedDriver = {}"
        />
      </div>
      <div class="pt-3">
        <span class="text-title-context">Select Driver</span>
        <cp-simple-select-custom
          v-model="selectedDriver"
          :options="filteredIndustries"
          :disabled="
            selectedIndustry ? (selectedIndustry.name ? false : true) : true
          "
          :textKey="textKeySN"
          valueKey="id"
        />
      </div>
      <b-row class="pt-3">
        <b-col class="text-right textError main-modal__button">
          {{ warningText }}
          <b-button
            variant="primary"
            class="main-modal__button-btn"
            @click="$emit('closeIscDriverModal', selectedDriver)"
            :disabled="Object.keys(selectedDriver).length === 0"
          >
            <span class="main-modal__button-title">Add</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div v-else class="centerDiv">
      <!-- <b-spinner variant="primary" label="Loading..."></b-spinner> 
      -->
      <b-button variant="primary" disabled>
        <b-spinner small type="grow"></b-spinner>
        <span class="main-modal__button-title"> Loading...</span>
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import IconCloseX from "../../components/icon/closeX.vue";
import CpSimpleSelectCustom from "../../components/select/CpSimpleSelectCustom.vue";
import { mapGetters } from "vuex";
export default {
  name: "AddIscDriverModal",
  components: {
    IconCloseX,
    CpSimpleSelectCustom,
  },
  props: {
    /* "ISCDrivers", */
    driverType: String,
    textKeySN: String, // SN (Social Network)
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: "",
      warningText: "",
      selectedCountry: {},
      selectedIndustry: {},
      selectedDriver: {},
      loading: false,
      ISCDrivers: [],
    };
  },
  async mounted() {
    if (this.getToken) {
      this.setGeoDrivers(this.getToken);
      this.loading = false;
    } else {
      this.loading = true;
      await this.$store.dispatch("settings/listen/geoDrivers", "all");
    }
  },
  methods: {
    showWarning: function (message) {
      this.warningText = message;
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    ...mapGetters({
      getToken: "settings/listen/getGeoDrivers",
    }),
    filteredIndustries: function () {
      if (this.selectedIndustry && this.selectedIndustry.drivers) {
        return this.selectedIndustry.drivers.filter(
          (d) => d.type == this.driverType
        );
      } else {
        return [];
      }
    },
  },
  watch: {
    getToken(geoDrivers) {
      if (geoDrivers) {
        this.setGeoDrivers(geoDrivers);
        this.loading = false;
      } else {
        this.loading = true;
      }
    },
  },
  methods: {
    setGeoDrivers(geoDrivers) {
      const data = geoDrivers;
      for (const country of Object.keys(data)) {
        const object = { ...data[country] };
        object["name"] = country;
        object["industries"] = [];
        for (const category of Object.keys(data[country].categories)) {
          object.industries.push({
            name: category,
            drivers: data[country].categories[category],
          });
        }
        delete object.categories;
        this.ISCDrivers.push(object);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-modal {
  &__button {
    &-btn {
      width: 66px;
      height: 34px;
      padding: 0px;
    }
    &-title {
      font-size: 12px;
    }
  }
}
.input {
  height: 35px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #fff;
  box-shadow: inset 5px 6px 44px -19px #c5d0e4;
}

.update-width {
  width: 30%;
}

.centerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
.style-loading {
  background-color: #c5d0e4;
}
</style>
