<template>
  <div 
    class="warning-info"
    :style="{ padding: paddingContainer }"
  > 
    <div class="warning-info-icon">
      <i class="fa fa-info-circle"></i>
    </div>
    <div class="warning-info-text">
      {{ infoText }}
    </div> 
  </div>
</template>

<script>
export default {
  name: 'CpWarningInfo',
  props:{
    infoText: {
      type: String,
      default: ""
    },
    paddingContainer: {
      type: String,
      default: "80px 20px" 
    }

  },
};
</script>

<style scoped>
  /* Warning info  */
.warning-info {
  display: flex;
  flex-direction: column;
  align-items: center;
 /*  padding: 80px 20px; */
}
.warning-info-icon {
  color: #00a5ff;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
}
.warning-info-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #5d6b88;
}
</style>