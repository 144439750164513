import {
  handlerRespAxiosMonitorLogin,
  handlerRespAxiosMonitorOverview,
  handlerRespAxiosMonitorCampaign,
} from "../../../../lib/handleResponse";

export default {
  //* QUERIES
  /*  async GET_ALL_ACCOMMODATION() {
    try {
     return  await handlerRespAxiosMonitorLogin("get",'user/allAccommodation', '');
    } catch(error) {
      console.log("[Error] in function: GET_ALL_ACCOMMODATION", error);
    }
  }, */


  //* POSTS OR UPDATES (MUTATIONS)
  //** SERVICES: BACK LOGIN */
  /**
   * * SERVICE: BACK-LOGIN
   ** Remove a social profile.
   * Inactive to flag 0 , to tables
   * T_CLIENT_TOKEN and T_CUENTAS_RED_SOCIAL
   * @param {*} _
   * @param {*} data
   * @return {*}
   */
  async DELETE_SOCIAL_PROFILE(_, data) {
    try {
      return await handlerRespAxiosMonitorLogin(
        "delete",
        "monitor/deleteSocialProfile",
        data
      );
    } catch (error) {
      console.log("[Error] in function: DELETE_SOCIAL_PROFILE  ", error);
      throw error;
    }
  },
/**
 ** SERVICE: BACK-LOGIN
 * Remove a social profile benchmark
 * @param {*} _
 * @param {*} data
 * @return {*} 
 */
async DELETE_BENCHMARK_SOCIAL_NETWORK(_, data) {
    try {
      return await handlerRespAxiosMonitorLogin(
        "delete",
        "monitor/deleteBench",
        data
      );
    } catch (error) {
      console.log("[Error] in function: DELETE_BENCHMARK_SOCIAL_NETWORK ", error);
      throw error;
    }
  },

 //** SERVICE: BACK-OVERVIEW */
/**
 ** SERVICE: BACK-OVERVIEW
 * validate if account exists
 * @param {*} _
 * @param {*} data
 * @return {*} 
 */
async GET_VALIDATE_ACCOUNT_BY_SN(_, data){
  try {
    return await handlerRespAxiosMonitorOverview(
      "post",
      "/accounts/validate-account",
      data
    );
  } catch (error) {
    console.log("[Error] in function: GET_VALIDATE_ACCOUNT_BY_SN ", error);
    throw error;
  }
 }, 
  //** SERVICE: BACK-CAMPAIGN  */ 
  async INSERT_CAMPAIGN(_, data){
    try {
       return await handlerRespAxiosMonitorCampaign(
        "post",
        "/campaign/add-multiple",
        data
      );
    } catch (error) {
      console.log("[Error] in function: INSERT_CAMPAIGN ", error);
      throw error;
    }
   }, 
  async DELETE_CAMPAIGN(_, data){
    try {
       return await handlerRespAxiosMonitorCampaign(
        "delete",
        "/campaign/delete-campaign",
        data
      );
    } catch (error) {
      console.log("[Error] in function: INSERT_CAMPAIGN ", error);
      throw error;
    }
   } 
};
