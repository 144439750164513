 export const  contextMixin = {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      redSocialTab: "tw",
      tabsSocialNetwork: [
        {
          name: "Twitter",
        },
        {
          name: "Facebook",
        },
        {
          name: "Instagram",
        },
        {
          name: "Linkedin",
        },
        {
          name: "Newspaper",
        },
      ],
    }    
  },
  methods: {
    changeTab(position) {
      let currentTab = "";
      switch (position) {
        case 0:
          currentTab = "tw";
          break;
        case 1:
          currentTab = "fb";
          break;
        case 2:
          currentTab = "ig";
          break;
        case 3:
          currentTab = "lk";
          break;
        case 4:
          currentTab = "nws";
          break;
      }
      // make a function
      this.redSocialTab = currentTab;
    },
  },
};