<template>
  <div>
    <b-modal
      no-close-on-backdrop
      v-model="model"
      size="lg"
      hide-footer
      :no-enforce-focus="true"
      class="main-modal"
      @close="closeModal"
    >
      <!-- Slot - modal title -->
      <template #modal-title> Update context </template>
      <template v-slot:modal-header-close>
        <icon-close-x />
      </template>
      <b-row class="text-container">
        <b-col cols="3" class="pl-0">
          <cp-input-text
            id="NameContext"
            placeholder="Context name"
            v-model="contextName"
            :validate="validateTitle"
            errorSubject="Context name"
            @no-errors="titleError"
          />
        </b-col>
        <b-col cols="9" class="pl-0">
          <b-button
            class="input-text__btn-pencil"
            @click="updateNameContext()"
            :disabled="buttonDisabled"
            v-bind:class="{ buttonDisabled }"
            variant="primary"
          >
            <span v-if="buttonLoading">
              <b-spinner small class="primary"></b-spinner>
            </span>
            <span v-else>
              <i class="fas fa-pencil-alt inactive"> </i>
            </span>
          </b-button>
        </b-col>
      </b-row>
      <div class="main-context">
        <tabs :cant="5" :titles="tabsSocialNetwork" @change="changeTab" />
        <div v-if="redSocialTab === 'tw'">
          <UpdateTwitterDrivers
            :formContext="formContext"
            @trigger-error="msgErrorModal"
          />
        </div>
        <div v-if="redSocialTab === 'fb'">
          <update-facebook-drivers 
            :formContext="formContext"
            @trigger-error="msgErrorModal"
          />
        </div>
        <div v-if="redSocialTab === 'ig'">
          <UpdateInstagramDrivers 
            :formContext="formContext"
            @trigger-error="msgErrorModal"
          />
        </div>
        <div v-if="redSocialTab === 'lk'">
          <UpdateLinkedinDrivers 
            :formContext="formContext"
            @trigger-error="msgErrorModal"
          />
        </div>
        <div v-if="redSocialTab === 'nws'">
          <update-news-paper-drivers 
            :formContext="formContext"
            @trigger-error="msgErrorModal"
          />
        </div>
      </div>
    </b-modal>
    <!-- MODAL ALERT GLOBAL -->
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="resultModalAlerts"
      :noCloseBackdrop="true"
    >
    </IscModalAlerts>
  </div>
  
</template>

<script>
import Tabs from "../../Tabs.vue";
import IconCloseX from "../../components/icon/closeX.vue";
import CpInputText from "../../components/input/CpInputText.vue";
import { contextMixin, alertSetting } from "../../../mixins/index";
import UpdateTwitterDrivers from "../context/UpdateTwitterDrivers.vue";
import UpdateFacebookDrivers from "../context/UpdateFacebookDrivers.vue";
import UpdateInstagramDrivers from "../context/UpdateInstagramDrivers.vue";
import UpdateLinkedinDrivers from "../context/UpdateLinkedinDrivers.vue";
import UpdateNewsPaperDrivers from "../context/UpdateNewspaperDrivers.vue";
import { mapActions } from "vuex";
import IscModalAlerts from "../../modal/IscModalAlerts.vue"
export default {
  name: "IscUpdateContext",
  components: {
    Tabs,
    CpInputText,
    IconCloseX,
    UpdateTwitterDrivers,
    UpdateFacebookDrivers,
    UpdateInstagramDrivers,
    UpdateLinkedinDrivers,
    UpdateNewsPaperDrivers,
    IscModalAlerts
  },
  mixins: [contextMixin, alertSetting],
  props: ["contextData"],
  data() {
    return {
      name: "",
      formContext: {
        contextId: "",
        name: "",
        twitterKeywords: [],
        facebookKeywords: [],
        newspaperKeywords: [],
        instagramKeywords: [],
        linkedinKeywords: [],
        allKeywords: []
      },
      UpdateISCDrivers: [],
      loading: false,
      contextName: "",
      buttonDisabled: false,
      buttonLoading: false,
      titleErrors: [],
      validateTitle: {
        required: true,
      },
    };
  },
  created() {
    if (!this.contextData) return;
    this.formContext.contextId = this.contextData.id;
    this.contextName = this.contextData.name;
    this.formContext.name = this.contextData.name;
    const filterTwitterKeywords = this.contextData.keywords.filter(function (
      ak
    ) {
      return ak.type == "tw";
    });
    const filterFacebookKeywords = this.contextData.keywords.filter(function (
      ak
    ) {
      return ak.type == "fb";
    });
    const filterInstagranKeywords = this.contextData.keywords.filter(function (
      ak
    ) {
      return ak.type == "ig";
    });
    const filterLinkedinKeywords = this.contextData.keywords.filter(function (
      ak
    ) {
      return ak.type == "lk";
    });
    const filterNewspaperKeywords = this.contextData.keywords.filter(function (
      ak
    ) {
      return ak.type == "nws";
    });
    this.formContext.twitterKeywords = filterTwitterKeywords;
    this.formContext.facebookKeywords = filterFacebookKeywords;
    this.formContext.instagramKeywords = filterInstagranKeywords;
    this.formContext.linkedinKeywords = filterLinkedinKeywords;
    this.formContext.newspaperKeywords = filterNewspaperKeywords;
  },
  methods: {
    ...mapActions({
      setNameContext: "settings/listen/UPDATE_CONTEXT",
    }),
    async updateNameContext() {
      // TODO: REFRESH DATA ONLY IF CHANGES EXISTS
      try {
        this.buttonLoading = true;
        const {
          data: { updateContext },
        } = await this.setNameContext({
          context_id: this.formContext.contextId,
          name: this.contextName.trim(),
        });
        if (updateContext.name) {
          this.contextName = updateContext.name;
          this.formContext.name = updateContext.name;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.buttonLoading = false;
      }
    },
    closeModal() {
      this.$emit("close-modal");
    },
    titleError(errors) {
      this.titleErrors = errors;
    },
    msgErrorModal(text){
      this.errorModal(text);
    }
  },
  watch: {
    contextName: {
      immediate: true,
      handler: function () {
        if (this.contextName.trim().length > 0) {
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.button-save {
  &__icon {
    font-size: 13px;
    color: #fff;
  }
  &__warning {
    font: 12px Oxygen;
    color: red;
  }
  &__btn {
    font: 13px Oxygen;
  }
  &__main {
    margin-left: 5px;
  }
}
::v-deep .modal-body {
  padding: 12px 20px;
}
.main-context {
  padding: 8px 0px;
  height: 100%;
}
.text-container {
  margin: 15px 0px;
  &__btn-pencil {
    background-color: #00a5ff;
  }
}
::v-deep .btn-secondary {
  border-color: white;
}
::v-deep .btn:focus {
  box-shadow: none;
}
</style>
