export const VARIABLE_SOCIAL_NETWORK = ["id", "name", "mask", "public"];
export const DASHBOARD_METRIC = [
  "count_samples",
  "histogram",
  "score_neupos",
  "score_pos",
  "sentiment",
  "updatedAt",
];

export const KEYWORD = [
  "id",
  "term",
  "page_url",
  "type",
  "name",
  "active",
  "newspaper_mask",
  "newspaper_name",
  "newspaper_section",
];
