<template>
  <div class="context-box">
    <div class="context-box__panel">
      <div class="context-box__panel-text">
        <p class="text-title-context">What:</p>
        <cp-input-text
          v-model="newDriverName"
          placeholder="Term, mention or Hashtag (One at a time)..."
          :validate="validateText"
          @no-errors="errorTerm"
        />
        <span v-if="warningText != ''" class="message-error">
          <i class="fa fa-exclamation-circle"></i> {{ warningText }}</span
        >
      </div>
      <div class="context-box__panel-select">
        <p class="text-title-context">Country:</p>
        <cp-simple-select-custom
          v-model="countrySelected"
          :options="countries"
          valueKey="id"
          textKey="name"
        />
      </div>
      <div class="context-box__panel-buttons">
        <b-button
          class=""
          size="md"
          :disabled="buttonDisabled"
          variant="primary"
          @click="addNewDriver()"
        >
          <span class="context-box__panel-buttons__title">Create</span>
        </b-button>
        <b-button
          v-if="showBtnDrivers"
          size="md"
          class="context-box__panel-buttons__btn-isc"
          @click="openDriverModal = true"
          variant="primary"
        >
          <span class="white--text mr-1">+</span
          ><span class="context-box__panel-buttons__title"> ISC Drivers</span>
        </b-button>
      </div>
    </div>
    <!-- TABLE List add items    -->
    <b-row class="context-box__table">
      <b-col>
        <div class="context-box__table__body">
          <cp-table-driver
            :items="allDrivers"
            @delete-item="deleteDriver"
            :fields="fields"
            title="Driver List"
          />
        </div>
      </b-col>
    </b-row>

    <AddIscDriverModal
      v-model="openDriverModal"
      @closeIscDriverModal="closeIscDriverModal"
      :driverType="'tw'"
      textKeySN="term"
    />
  </div>
</template>

<script>
import AddIscDriverModal from "../modal/AddIscDriverModal.vue";
import CpInputText from "../../components/input/CpInputText.vue";
import CpSimpleSelectCustom from "../../components/select/CpSimpleSelectCustom.vue";
import CpTableDriver from "../../components/table/CpTableDriver.vue";
import { socialNetwork, twitterMixin } from "../../../mixins/index";
export default {
  name: "CreateTwitterDrivers",
  mixins: [ socialNetwork, twitterMixin ],
  components: {
    AddIscDriverModal,
    CpInputText,
    CpSimpleSelectCustom,
    CpTableDriver,
  },
  data() {
    return {
      newDriverName: "",
      newDriverCountryId: "all",
      openDriverModal: false,
      fields: [
        { key: "term", class: "text-row-01" },
        {
          key: "country",
          class: "text-row-02",
        },
        {
          key: "delete",
        },
        { key: "active" },
      ],
    };
  },
  methods: {
    addNewDriver: function () {
      if (!this.newDriverName) return;
      const newDriver = {
        term: this.newDriverName.trim(),
        place_id: this.newDriverCountryId,
        country: this.countries.find((c) => c.id == this.newDriverCountryId)
          .name,
        active: true,
        added: new Date().valueOf(),
        geo_dashboard: false,
        type: "tw",
      };

      if (
        this.allDriversToAdd.some(
          (d) => d.term == newDriver.term && d.place_id == newDriver.place_id
        )
      ) {
        this.showWarning("Driver already added");
      } else {
        // Set to default
        this.newDriverName = "";
        this.newDriverCountryId = "all";
        this.countrySelected = {
          id: "all",
          name: "All (Around the world)",
        };
        this.$emit("driverToAdd", newDriver);
      }
    },
    closeIscDriverModal(driver) {
      this.openDriverModal = false;
      if (driver) {
        if (
          this.allIscDriversToAdd.some(
            (d) => d.term == driver.term && d.place_id == driver.place_id
          )
        ) {
          this.showWarning("Driver already added");
        } else {
          const newDriver = {
            ...driver,
            added: new Date().valueOf(),
            country: this.countries.find((c) => c.id == driver.place_id).name,
            geo_dashboard: true,
          };
          this.$emit("iscDriverToAdd", newDriver);
        }
      }
    },
  },
  watch: {
    newDriverName: {
      immediate: true,
      handler: function (x) {
        if (
          this.newDriverName.trim().length > 0 &&
          this.termError.length === 0
        ) {
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.context-box {
  padding: 12px 45px;
  .btn {
    font-size: 14px;
    background: #00a5ff;
  }
  &__panel {
    display: flex;
    justify-content: space-around;
    margin-bottom: 35px;
    &-select {
      width: 160px;
    }
    &-text {
      height: 56px;
      width: 270px;
    }
    &-buttons {
      padding-top: 24px;
      width: 176px;
      &__title {
        font-size: 12px;
      }
      &__btn-isc {
        margin-left: 4px;
      }
    }
  }
  &__table {
    padding: 0px 34px;
  }
}
</style>
<!-- global settings styles -->
<style lang="css">
.text-title-context {
  color: #a2acc4;
  font: 14px "Oxygen";
  margin-bottom: 5px;
}
</style>
