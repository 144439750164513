<template>
  <div class="context-box">
    <div class="context-box__panel">
      <div class="context-box__panel-text">
        <p class="text-title-context">What:</p>
        <cp-input-text
          v-model="newDriverName"
          placeholder="Term, mention or Hashtag (One at a time)..."
          :validate="validateText"
          @no-errors="errorTerm"
        />
        <span v-if="warningText != ''" class="message-error">
          <i class="fa fa-exclamation-circle"></i> {{ warningText }}</span
        >
      </div>
      <div class="context-box__panel-select">
        <p class="text-title-context">Country:</p>
        <cp-simple-select-custom
          v-model="countrySelected"
          :options="countries"
          valueKey="id"
          textKey="name"
        />
      </div>
      <div class="context-box__panel-buttons">
        <b-button
          class=""
          size="md"
          :disabled="buttonDisabled"
          variant="primary"
          @click="addNewDriver()"
        >
          <span class="context-box__panel-buttons__title">Create</span>
        </b-button>
        <b-button
          v-if="showBtnDrivers"
          size="md"
          class="context-box__panel-buttons__btn-isc"
          @click="openDriverModal = true"
          variant="primary"
        >
          <span class="white--text mr-1">+</span
          ><span class="context-box__panel-buttons__title"> ISC Drivers</span>
        </b-button>
      </div>
    </div>
    <!-- TABLE List add items    -->
    <b-row class="context-box__table">
      <b-col>
        <div class="context-box__table__body">
          <!-- @update-toggle-active-tw="toggleActiveTwitter" // add this if it is required again -->
          <cp-table-driver
            :items="formContext.twitterKeywords"
            @delete-item="deleteKeyword"
            @update-toggle-active="toggleActive"
            :fields="fields"
            title="Driver List"
            :isBusy="isBusyTable"
          />
        </div>
      </b-col>
    </b-row>

    <AddIscDriverModal
      v-model="openDriverModal"
      @closeIscDriverModal="closeIscDriverModal"
      :driverType="'tw'"
      textKeySN="term"
    />
  </div>
</template>

<script>
import AddIscDriverModal from "../modal/AddIscDriverModal.vue";
import CpInputText from "../../components/input/CpInputText.vue";
import CpSimpleSelectCustom from "../../components/select/CpSimpleSelectCustom.vue";
import CpTableDriver from "../../components/table/CpTableDriver.vue";
import { socialNetwork, twitterMixin } from "../../../mixins/index";

export default {
  name: "UpdateTwitterDrivers",
  mixins: [socialNetwork, twitterMixin],
  components: {
    AddIscDriverModal,
    CpInputText,
    CpSimpleSelectCustom,
    CpTableDriver,
  },
  data() {
    return {
      IstwitterActiveColor: "",
      newDriverCountryId: "all",
      statusActive: false,
      newDriverName: "",
      openDriverModal: false,
      fields: [
        { key: "term", class: "text-row-01" },
        {
          key: "place_id",
          class: "text-row-02",
          formatter: (value) => this.getCountryName(value),
        },
        { key: "activeTwitter" },
        {
          key: "delete",
        },
        { key: "active" },
      ],
    };
  },
  methods: {
    async addNewDriver() {
      const newDriver = {
        term: this.newDriverName.trim(),
        place_id: this.newDriverCountryId,
        country: this.countries.find((c) => c.id === this.newDriverCountryId)
          .name,
        active: true,
      };

      if (
        this.formContext.twitterKeywords.some(
          (d) => d.term == newDriver.term && d.place_id === newDriver.place_id
        )
      ) {
        this.showWarning("Driver already added");
      } else {
        this.activeLoading(true);
        const variables = {
          context_id: this.formContext.contextId,
          driver: {
            term: newDriver.term,
            place_id: newDriver.place_id,
            active: newDriver.active,
          },
          type: "tw",
        };

        try {
          const {
            data: { addKeyword },
          } = await this.addKeywordListen(variables);
          if (addKeyword.id) {
            this.formContext.twitterKeywords.push(addKeyword);
            this.newDriverName = "";
            this.newDriverCountryId = "all";
            this.countrySelected = {
              id: "all",
              name: "All (Around the world)",
            };
          }
        } catch (error) {
          console.error("[Error] in function addNewDriver ", error);
          this.mainProcessErrorGraphQl();
        } finally {
          this.activeLoading(false);
        }
      }
    },
    getCountryName(place_id) {
      if (place_id) {
        const country = this.countries.find((c) => c.id === place_id);
        if (!country) {
          return "unknown";
        } else {
          return country.name;
        }
      } else {
        return "unknown";
      }
    },
    async toggleActive(id, status) {
      this.activeLoading(true);
      if (id) {
        const variables = {
          context_id: this.formContext.contextId,
          keyword_id: id,
          type: "tw",
          active: status,
        };
        try {
          await this.setActiveToggleKeyword(variables);
          /* 
            const {
            data: { toggleActiveKeyword },
          } = await ....
            if (toggleActiveKeyword[0].active === status) {
            this.formContext.twitterKeywords = this.formContext.twitterKeywords.map(function (k) {
              if (k.id == id) {
                k.active = status;
              }
              return k;
            });
          } */
        } catch (error) {
          // If there is an error to get back from old formContext.twitterKeywords
          this.formContext.twitterKeywords = this.formContext.twitterKeywords.map(
            function (k) {
              if (k.id === id) {
                k.active = !status;
              }
              return k;
            }
          );
          console.error("[Error] in function toggleActive(tw) ", error);
          this.emitError("There was an error to change a keyword'status.");
        } finally {
          this.activeLoading(false);
        }
      }
    },
    //* Uncomment if it is required again
    // async toggleActiveTwitter(id, status) {
    //   this.activeLoading(true);
    //   if (id) {
    //     // console.log('toggleActiveTwitter');
    //     const variables = {
    //       client_id: this.client_id,
    //       keyword_id: id,
    //       active: status,
    //     };
    //     try {
    //       //Important: declaring the variable "toggleTwitterKeyword"
    //       // the code will finish on the line 230 because the attribute will be
    //       //null if we remove it, It will continue.

    //       const {
    //         data: { toggleTwitterKeyword },
    //       } = await this.setActiveToggleKeywordTw(variables);
    //       this.formContext.twitterKeywords = this.formContext.twitterKeywords.map(
    //         function (k) {
    //           if (k.id === id) k.tw_active = status;
    //           return k;
    //         }
    //       );
    //     } catch (error) {
    //       let dataError = sessionStorage.getItem("errorMessage");
    //       dataError = dataError.replace("GraphQL error: ", "");
    //       this.warningText = dataError;
    //       console.error("[Error] in function toggleActiveTwitter: ", error);
    //     } finally {
    //       setTimeout(() => {
    //         this.warningText = "";
    //         sessionStorage.removeItem("errorMessage");
    //       }, 5000);
    //       this.activeLoading(false);
    //     }
    //   }
    // },
    async deleteKeyword(item) {
      if (this.getAllKeywordsLength() > 1) {
        this.activeLoading(true);
        if (item.id) {
          const variables = {
            context_id: this.formContext.contextId,
            keyword_id: item.id,
            type: "tw",
          };
          try {
            const {
              data: { deleteKeyword },
            } = await this.deleteKeywordListen(variables);

            if (deleteKeyword == "keyword deleted") {
              this.formContext.twitterKeywords = this.formContext.twitterKeywords.filter(
                (k) => k.id !== item.id
              );
            } else {
              this.emitError("Server fails tp delete a keyword Twitter");
            }
          } catch (error) {
            console.error("[Error] in function  deleteKeyword (TW) ", error);
            this.mainProcessErrorGraphQl();
          } finally {
            this.activeLoading(false);
          }
        }
      } else {
        //Eliminar contexto (para que no esté vacío)
        this.emitError("At least you must have one driver in your context.");
      }
    },
    async closeIscDriverModal(driver) {
      this.openDriverModal = false;
      if (driver) {
        if (
          this.formContext.twitterKeywords.some(
            (d) => d.term == driver.term && d.place_id === driver.place_id
          )
        ) {
          this.showWarning("Driver already added");
        } else {
          this.activeLoading(true);
          const variables = {
            context_id: this.formContext.contextId,
            keyword_id: driver.id,
            active: true,
            type: "tw",
          };
          try {
            const {
              data: { addGeoKeyword },
            } = await this.addGeoKeywordListen(variables);
            if (addGeoKeyword.id) {
              addGeoKeyword.active = true;
              addGeoKeyword["geo_dashboard"] = true;
              this.formContext.twitterKeywords.push(addGeoKeyword);
            } else {
              this.emitError("Server fails to add a driver ISC.");
            }
          } catch (error) {
            console.error("[Error] in function closeIscDriverModal ", error);
            this.mainProcessErrorGraphQl();
          } finally {
            this.activeLoading(false);
          }
        }
      }
    },
    activeLoading(value) {
      this.isBusyTable = value;
      if (value === true) {
        this.buttonDisabled = true;
      } else {
        this.checkName();
      }
    },
    checkName() {
      if (this.newDriverName.trim().length > 0 && this.termError.length === 0) {
        this.buttonDisabled = false;
      } else {
        this.buttonDisabled = true;
      }
    },
  },
  watch: {
    newDriverName: {
      immediate: true,
      handler: function (x) {
        this.checkName();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.context-box {
  padding: 12px 45px;
  .btn {
    font-size: 14px;
    background: #00a5ff;
  }
  &__panel {
    display: flex;
    justify-content: space-around;
    margin-bottom: 35px;
    &-select {
      width: 160px;
    }
    &-text {
      height: 56px;
      width: 270px;
    }
    &-buttons {
      padding-top: 24px;
      width: 176px;
      &__title {
        font-size: 12px;
      }
      &__btn-isc {
        margin-left: 4px;
      }
    }
  }
  &__table {
    padding: 0px 34px;
  }
}
</style>
