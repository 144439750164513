var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100 position-relative tab-box" }, [
    _c(
      "div",
      { staticClass: "d-flex w-100 bg-white position-relative z-0" },
      _vm._l(_vm.cant, function(n) {
        return _c(
          "div",
          {
            key: n,
            class: "p-1 " + (n === 0 ? "" : "pr-1"),
            style: "width : " + 100 / _vm.cant + "%"
          },
          [_c("div", { staticClass: "bg-mainbg" })]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "w-100 position-absolute d-flex top-0",
        staticStyle: { "z-index": "2" }
      },
      [
        _c(
          "div",
          { staticClass: "py-1", style: "width : " + 100 / _vm.cant + "%" },
          [
            _c("div", {
              ref: "bgTab",
              staticClass: "bgTab-top bg-white border-item-translate w-100",
              style: "transform: translateX(" + _vm.translate + "px)"
            })
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "position-absolute w-100 font-weight-bold d-flex top-0",
        staticStyle: { "z-index": "2" }
      },
      _vm._l(_vm.titles, function(title, index) {
        return _c(
          "div",
          {
            key: index + "-titles",
            class: "cursor-pointer " + (index === 0 ? "p-1" : "py-1 pr-1"),
            style: "width :  " + 100 / _vm.cant + "%",
            on: {
              click: function($event) {
                return _vm.setTabActive(index)
              },
              mouseenter: function($event) {
                return _vm.translateBg($event, index)
              },
              mouseleave: function($event) {
                return _vm.resetBg($event)
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "tab-box-item",
                class: [
                  "w-100",
                  "d-flex align-items-center",
                  "justify-content-center content-text",
                  _vm.activePos == index ? "color-text" : "text-secondary",
                  _vm.tempActive == index ? "color-text" : "text-secondary"
                ]
              },
              [
                _c("span", { staticClass: "text-lsm" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(title.name) +
                      " " +
                      _vm._s(title.icon ? " " : "") +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                title.icon ? _c("i", { class: title.icon }) : _vm._e()
              ]
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }