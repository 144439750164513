<template>
  <div 
    class="warning-info"
    :style="{ padding: paddingContainer, 'justify-content': justifyContent }"
  > 
    <div class="warning-info-icon">
      <i class="fa fa-info-circle"></i>
    </div>
    <div class="warning-info-text">
      {{ message }}
    </div> 
  </div>
</template>

<script>
export default {
  name: 'CpWarningMessage',
  props:{
    message: {
      type: String,
      default: ""
    },
    paddingContainer: {
      type: String,
      default: "" 
    },
    justifyContent: {
      type: String,
      default: "unset"
    }

  },
};
</script>

<style scoped>
  /* Warning horizontal message */
.warning-info {
  display: flex;
  font-size: 10px;
  line-height: 16px;
  
}
.warning-info-icon {
  color: #00a5ff;
  font-weight: 900;
  margin-right: 3px;
}
.warning-info-text {
  font-weight: 400;
  color: #A4ACC2;
}
</style>