var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "context-box" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "context-box__panel" },
        [
          _c(
            "b-col",
            { attrs: { cols: "3" } },
            [
              _c("p", { staticClass: "text-title-context" }, [
                _vm._v("Country:")
              ]),
              _vm._v(" "),
              _c("cp-simple-select-custom", {
                attrs: {
                  options: _vm.countries,
                  disabled: _vm.allNewspapers.length == 0
                },
                on: { input: _vm.changeSelectPlace },
                model: {
                  value: _vm.nwsCountrySelected,
                  callback: function($$v) {
                    _vm.nwsCountrySelected = $$v
                  },
                  expression: "nwsCountrySelected"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "" } },
            [
              _c("p", { staticClass: "text-title-context" }, [
                _vm._v("Newspaper:")
              ]),
              _vm._v(" "),
              _c("cp-simple-select-custom", {
                attrs: {
                  options: _vm.nwsNames,
                  disabled: !_vm.nwsCountrySelected
                },
                on: { input: _vm.changeSelectName },
                model: {
                  value: _vm.nwsNameSelected,
                  callback: function($$v) {
                    _vm.nwsNameSelected = $$v
                  },
                  expression: "nwsNameSelected"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("p", { staticClass: "text-title-context" }, [
                _vm._v("Section:")
              ]),
              _vm._v(" "),
              _c("cp-simple-select-custom", {
                attrs: { options: _vm.nwsUrls, disabled: !_vm.nwsNameSelected },
                on: { input: _vm.changeSelectUrl },
                model: {
                  value: _vm.nwsUrlSelected,
                  callback: function($$v) {
                    _vm.nwsUrlSelected = $$v
                  },
                  expression: "nwsUrlSelected"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "context-box__panel-buttons", attrs: { cols: "1" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    size: "md",
                    disabled: !_vm.nwsToAdd,
                    variant: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.addNewDriver()
                    }
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "context-box__panel-buttons__title" },
                    [_vm._v("Add")]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.warningText != ""
        ? _c("span", { staticClass: "message-error" }, [
            _c("i", { staticClass: "fa fa-exclamation-circle" }),
            _vm._v(" " + _vm._s(_vm.warningText))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "context-box__table" },
        [
          _c("b-col", [
            _c(
              "div",
              { staticClass: "context-box__table__body" },
              [
                _c("cp-table-driver", {
                  attrs: {
                    items: _vm.allDriversToAdd,
                    fields: _vm.fields,
                    title: "Profile Page List"
                  },
                  on: { "delete-item": _vm.deleteDriver }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "context-box__text" }, [
      _c("p", { staticClass: "text-title-context" }, [_vm._v("What:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }