var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "text-title" }, [
        _c("span", { staticClass: "text-title-context" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      ]),
      _vm._v(" "),
      _c("b-table", {
        staticClass: "table-driver",
        attrs: {
          "sticky-header": "",
          fields: _vm.fields,
          items: _vm.items,
          "thead-class": _vm.theadClass,
          busy: _vm.isBusy
        },
        scopedSlots: _vm._u([
          {
            key: "cell(delete)",
            fn: function(data) {
              return [
                _c("i", {
                  staticClass: "fas fa-trash delete-action",
                  on: {
                    click: function($event) {
                      return _vm.deleteItem(data.item)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "cell(active)",
            fn: function(data) {
              return [
                data.item.active === true
                  ? _c("i", {
                      staticClass: "fa fa-eye active",
                      on: {
                        click: function($event) {
                          data.item.active = false
                          _vm.toggleActive(data.item.id, false)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                data.item.active === false
                  ? _c("i", {
                      staticClass: "fa fa-eye inactive",
                      on: {
                        click: function($event) {
                          data.item.active = true
                          _vm.toggleActive(data.item.id, true)
                        }
                      }
                    })
                  : _vm._e()
              ]
            }
          },
          {
            key: "table-busy",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "text-center text-primary my-2" },
                  [
                    _c("b-spinner", { staticClass: "align-middle" }),
                    _vm._v(" "),
                    _c("strong", [_vm._v("Loading...")])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }