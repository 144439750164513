import gql from "graphql-tag";

export const CREATE_CONTEXT = gql(`
  mutation NewContext($account_id: String, $name: String, $facebook_drivers: [NewDriver!], $twitter_drivers: [NewDriver!], $instagram_drivers: [NewDriver!],	$linkedin_drivers: [NewDriver!], $newspapers_drivers: [NewDriver!]) {
    createContext : createContext(account_id: $account_id, name: $name, facebook_drivers: $facebook_drivers, twitter_drivers: $twitter_drivers, instagram_drivers: $instagram_drivers, linkedin_drivers: $linkedin_drivers, newspapers_drivers: $newspapers_drivers) {
      id
      account_id
      country_id
      updatedAt
      name
    }
  }
`);

export const ADD_GEO_KEYWORD = gql(`
  mutation addGeoKeyword($context_id: String, $keyword_id: String, $active:Boolean, $type: String) {
    addGeoKeyword : addGeoKeyword(context_id: $context_id, keyword_id: $keyword_id, active: $active, type: $type) {
      id
      active
      type
      place_id
      term
      mask
      name
      page_id
      page_url
    }
  }
`);
export const DELETE_CONTEXT = gql(`
  mutation deleteContext($context_id: String) {
    deleteContext : deleteContext(context_id: $context_id)
  }
`);
export const UPDATE_CONTEXT = gql(`
  mutation UpdateContext($context_id: String, $name: String) {
    updateContext : updateContext(context_id: $context_id, name: $name) {
      id
      account_id
      updatedAt
      name
    }
  }
`);
export const ADD_KEYWORD = gql(`
  mutation addKeyword($context_id: String, $driver: NewDriver, $type: String) {
    addKeyword : addKeyword(context_id: $context_id, driver: $driver, type: $type){
      id
      active
      type
      place_id
      term
      mask
      name
      page_id
      page_url
      newspaper_name
		  newspaper_section
    }
  }
`);
export const DELETE_KEYWORD = gql(`
  mutation deleteKeyword($context_id: String, $keyword_id: String, $type: String) {
    deleteKeyword : deleteKeyword(context_id: $context_id, keyword_id: $keyword_id, type: $type)
  }
`);
export const TOGGLE_ACTIVE_KEYWORD = gql(`
  mutation toggleActiveKeyword(
    $context_id: String!
    $keyword_id: String!
    $type: String!
    $active: Boolean
  ) {
    toggleActiveKeyword : toggleActiveKeyword(
      context_id: $context_id
      keyword_id: $keyword_id
      type: $type
      active: $active
    ) {
      id
      term
      mask
      active
    }
  }
`);
export const TOGGLE_ACTIVE_KEYWORD_TWITTER = gql(`
  mutation toggleTwitterKeyword(
    $client_id: String!
    $keyword_id: String!
    $active: Boolean
  ) {
    toggleTwitterKeyword : toggleTwitterKeyword(
      client_id: $client_id
      keyword_id: $keyword_id
      active: $active
    ) {
      driver_id
      newStatus
    }
  }
`);

