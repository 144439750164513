var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tooltip",
        {
          staticClass: "tooltipBlue",
          attrs: { target: _vm.target, title: _vm.title, triggers: "hover" }
        },
        [_vm._v("\n    " + _vm._s(_vm.message) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }