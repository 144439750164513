export default {
  getGeoDrivers: state => state.geoDrivers,
  getGeoDriversFb: state => state.geoDriversFb,
  getGeoDriversTw: state => state.geoDriversTw,
  geoDriversIg: state => state.geoDriversIg,
  geoDriversLk: state => state.geoDriversLk,
  allAddedIscDrivers: state => state.allAddedIscDrivers,
  allAddedDrivers: state => state.allAddedDrivers,
  getNameContext: state => state.nameContext,
  getGlobalVarContext: state => state.globalVarContext,
  getGlobalIscDrivers: state => state.globalIscDrivers,
}
