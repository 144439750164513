<template>
  <div>
    <b-tooltip
      :target="target"
      :title="tooltipTitle"
      triggers="hover"
      custom-class="tooltipDouble"
      variant="light"
    >
      <div v-for="(item, key) in columns" :key="key" class="containerColumn">
        <div class="header flex-center">
          <p>{{ item.header }}</p>
        </div>
        <div class="container-body d-flex flex-center">
          <div class="body">
            <div
              class="d-flex align-item-center"
              v-if="item.body.facebook.value"
            >
              <div>
                <i class="fab fa-facebook i-social-networks"></i>
              </div>
              <p>
                <strong class="p-strong">Facebook: </strong
                >{{ item.body.facebook.message }}
              </p>
            </div>
            <div
              class="d-flex align-item-center"
              v-if="item.body.instagram.value"
            >
              <div>
                <i class="fab fa-instagram i-social-networks"></i>
              </div>
              <p>
                <strong class="p-strong">Instagram:</strong>
                {{ item.body.instagram.message }}
              </p>
            </div>
            <div
              class="d-flex align-item-center"
              v-if="item.body.linkedin.value"
            >
              <div>
                <i class="fab fa-linkedin i-social-networks"></i>
              </div>
              <p>
                <strong class="p-strong">LinkedIn:</strong>
                {{ item.body.linkedin.message }}
              </p>
            </div>
            <div
              class="d-flex align-item-center"
              v-if="item.body.twitter.value"
            >
              <div>
                <i class="fab fa-twitter i-social-networks"></i>
              </div>
              <p>
                <strong class="p-strong">Twitter:</strong>
                {{ item.body.twitter.message }}
              </p>
            </div>
            <div
              class="d-flex align-item-center"
              v-if="item.body.youtube.value"
            >
              <div>
                <i class="fab fa-youtube i-social-networks"></i>
              </div>
              <p>
                <strong class="p-strong">YouTube:</strong>
                {{ item.body.youtube.message }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: "CpTooltipTableCustom",
  data() {
    return {};
  },
  props: {
    target: {
      type: String,
      default: "",
    },
    tooltipTitle: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {},
  created() {},
};
</script>

<style lang="css" scoped>
/* ::v-deep .arrow::before {
    border-top-color: transparent !important;
} */
::v-deep .tooltip-inner {
  padding: 0px;
  display: flex;
  max-width: 100%;
  width: 526px;
  height: 178px;
  box-shadow: 0px 2px 6px rgba(107, 115, 139, 0.2);
}
::v-deep .tooltip-inner .containerColumn:nth-child(2) .body {
  border-left: solid 1px rgba(107, 115, 139, 0.1);
}
.containerColumn {
  width: 50%;
  background: #ffffff;
}
.header {
  background: #e5f6ff;
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 14px;
  text-align: center;
  color: #2c3852;
  height: 46px;
}
.container-body{
  height: 132px;
}
.body {
  background: #ffffff;
  padding: 0rem 0.5rem 0rem 1rem;
  height: 90px;
}
.body div p {
  text-align: start;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  /* line-height: 19px; */
  color: #6b738b;
}

.i-social-networks {
  font-size: 12px;
  color: #6b738b;
  padding-right: 0.2rem;
}

.p-strong {
  font-weight: 900;
}
</style>
