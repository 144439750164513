var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tooltip",
        {
          attrs: {
            target: _vm.target,
            title: _vm.tooltipTitle,
            triggers: "hover",
            "custom-class": "tooltipDouble",
            variant: "light"
          }
        },
        [
          _c("div", { staticClass: "containerDivs" }, [
            _c(
              "div",
              {
                staticClass: "containerDiv1 flex-center",
                style: {
                  height: _vm.heightSubTitle1,
                  background: _vm.backgroundColorSubtitle1,
                  color: _vm.colorTextSubtitle1,
                  width: _vm.widthSubtitle1,
                  "text-align": _vm.textAlignSubtitle1
                }
              },
              [
                _c("p", { staticClass: "title" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.title))])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "subTitle1" }, [
                  _vm._v(_vm._s(_vm.subTitle1))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "containerDiv2 flex-center",
                style: {
                  height: _vm.heightSubTitle2,
                  color: _vm.colorTextSubtitle2,
                  "text-align": _vm.textAlignSubtitle2
                }
              },
              [
                _c("p", { staticClass: "subTitle2" }, [
                  _vm._v(_vm._s(_vm.subTitle2))
                ])
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }