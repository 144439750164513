var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "custom-select selectCustom d-flex align-items-center",
        class: [_vm.disabled ? "disabledSelect" : ""]
      },
      [
        _c("multiselect", {
          attrs: {
            options: _vm.options,
            disabled: _vm.disabled,
            searchable: false,
            "close-on-select": true,
            "custom-label": _vm.nameWithLang,
            label: "name"
          },
          on: { select: _vm.selectValue },
          model: {
            value: _vm.valueSelected,
            callback: function($$v) {
              _vm.valueSelected = $$v
            },
            expression: "valueSelected"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }