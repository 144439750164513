import gql from "graphql-tag";
import { VARIABLE_SOCIAL_NETWORK, DASHBOARD_METRIC, KEYWORD } from "./variables";
export const GET_GEO_DRIVERS = gql(`
  query geoDrivers($social_network: String){
    geoDrivers(social_network: $social_network){
      items
    }
  }
`);

export const FB_FAN_PAGE_CHECK = gql(`
  query FbFanpageCheck($fanpage_url: String) {
    fbFanpageCheck(fanpage_url: $fanpage_url) {
      ${VARIABLE_SOCIAL_NETWORK}
      likes
    }
  }
`);
export const IG_PROFILE_PAGE_CHECK = gql(`
  query IgPageCheck($page_url: String) {
    igPageCheck(page_url: $page_url) {
      ${VARIABLE_SOCIAL_NETWORK}
      image
    }
  }
`);
export const LK_PROFILE_PAGE_CHECK = gql(`
  query LkPageCheck($page_url: String) {
    lkPageCheck(page_url: $page_url) {
      ${VARIABLE_SOCIAL_NETWORK}
      image
    }
  }
`);
export const NEWSPAPER_DRIVERS = gql(`
  query GetNewspapersDrivers {
    getNewspapersDrivers {
      id
      social_network
      place_id
      geo_dashboard
      newspaper_logo
      newspaper_mask
      newspaper_name
      newspaper_section
      page_url
    }
  }
`);
//* get all contexts by account
export const GET_OVERVIEWS = gql(`
  query GetContextsByAccount($account_id: String) {
    getContextsByAccount : getContextsByAccount(account_id: $account_id) {
        id
        name
        createdAt
        dashboard_metrics {
          ${ DASHBOARD_METRIC }
        }
        keywords{
          ${ KEYWORD }
        }
      }
  }
`);
export const GET_CONTEXT_BY_ID = gql(`
  query GetContextById($context_id: String) {
    getContextById : getContextById(context_id: $context_id) {
      id
      dashboard_metrics {
        id
        context_id
        histogram
        count_samples
        score_neupos
        score_pos
        sentiment
      }
      account_id
      country_id
      createdAt
      updatedAt
      name
      keywords {
        id
        page_url
        page_id
        mask
        createdAt
        updatedAt
        active
        type
        place_id
        premium
        term
        name
        geo_dashboard
        tw_active
        newspaper_mask
        newspaper_name
        newspaper_section
      }
    }
  }
`);