import listen from "./listen/index";
import profile from "./profile/index";
import monitor from "./monitor/index";
export default {
  modules: {
    listen,
    profile,
    monitor
  },
  namespaced: true
};
