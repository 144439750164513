<template>
  <div
    class="breadcrumb-c d-flex justify-content-between align-items-center"
  >
    <div>
      <span class="text-capitalize" v-for="(level, index) in levelsActive" :key="index">
        {{ replacenames(level,levelsActive[index-1]) }}
        <span class="mx-1">/</span>
      </span>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BreadCrumb",
  data() {
    return {
      levelsActive: [],
    };
  },
  created() {
    const { path } = this.$route;
    const levels = path.slice(1).split("/");
    this.levelsActive = levels;
  },
  methods:{
    regresarPaginaAnterior(){
      window.history.back();
    },
     replacenames(nameValue,valPrev){
        if (nameValue=="detail" && valPrev=="accounts")  {
             nameValue= "Account Overview"
        }else if (nameValue=="detail" && valPrev=="channels") {
          nameValue= "Channels Overview"
        } 
        return nameValue;    
     },
  },
 
};
</script>

<style scoped>
.breadcrumb-c {
  font-weight: 300;
  font-size: 12px;
  background-color: white;
  color: rgb(78, 77, 77);
  width: 100%;
  height: 55px;
  padding: 10px 20px 10px 19px;
  top: 0; 
  z-index: 3;
}
.btn-bc {
  border-radius: 0.5rem;
  transition: all ease-in 0.2s;
}

.btn-bc:hover {
  color: rgb(78, 77, 77);
}

.btn-bc:focus {
  outline: none;
}
</style>