<template>
  <b-modal
    v-model="model"
    :size="sizeModal"
    :no-enforce-focus="true"
    hide-footer
    no-close-on-backdrop
    @close="closeModal"
  >
    <template v-slot:modal-header-close>
      <icon-close-x />
    </template>
    <template #modal-title> {{ title }} </template>
    <div class="main">
      <b-form>
        <div v-for="(field, index) in formFields" :key="index">
          <b-form-group
            :id="`input-group-${index}`"
            :label="field.label"
            :label-for="`input-${index}`"
          > 
            <!-- SECTION: PASSWORD  -->
            <b-input-group v-if="field.activePassword">
              <cp-input-text
                :style="{width: widthInpPwd }"
                class="main__input-password"
                :id="`input-${index}`"
                :type="field.type"
                v-model="field.value"
                @no-errors="setErrors($event, index)"
                :validate="field.validate"
                :errorSubject="field.label"
              />
              <b-input-group-append>
                <b-input-group-text
                  class="main__input-password--container-icon"
                  @click="showPassword(field.type, index)"
                >
                  <i 
                    class="main__input-password--icon"
                    :class="[field.type === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'] "
                  ></i>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <template v-else>
              <template v-if="field.type === 'checkbox'">
                <b-form-checkbox switch v-model="field.value"></b-form-checkbox>
              </template>
              <cp-input-text
                  v-else
                  :id="`input-${index}`"
                  :type="field.type"
                  v-model="field.value"
                  :validate="field.validate"
                  @no-errors="setErrors($event,index)"
                />
            </template>     
          </b-form-group>
        </div>

        <b-row v-if="isErrorGlobal" class="message-error main__box-error">
          <b-col cols="12">
            <i class="fa fa-exclamation-circle"></i>
            {{ errorMsgGlobal }}
          </b-col>
        </b-row>
        <div class="main__btn-container">
          <b-button 
            class="main__btn-container__btn-save" 
            variant="primary" 
            :disabled="disabledBtn"
            @click="onSubmit"
          >
            {{ btnText }}
          </b-button>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import IconCloseX from "../../component-library/components/icon/closeX.vue";
import CpInputText from "../components/input/CpInputText.vue";
export default {
  name: "CpIscFormModal",
  components: {
    IconCloseX,
    CpInputText,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "",
    },
    formFields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    errorMsgGlobal: {
      type: String,
      default: "",
    },
    isErrorGlobal: {
      type: Boolean,
      default: false
    },
    btnDisabled: {
      type: Boolean,
      default: true
    },
    widthInpPwd: {
      type: String,
      default: "91%"
    },
    sizeModal:{
      type: String,
      default: "md"
    }

  },
  data() {
    return {
      errors: [],
      disabledBtn: true,
      passFirstRequire: true,
      isErrorMessage: false,
      messageError: ""
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {},
  created() {
    if(this.isEdit) this.disabledBtn = false
  },
  methods: {
    onSubmit() {
      //Steps
      this.$emit("form-submit", this.formFields);
    },
    closeModal() {
      this.formFields.forEach( field => field.value = "" );
      this.$emit('closed-modal');
    },
    setErrors(arrErrors, index) {
      this.formFields[index].errors = arrErrors;
      if(this.passFirstRequire){
        //* Required Values validation
        let isRequiredField = this.formFields.some((field) => field.validate.required && !field.value);
        if(isRequiredField) return;
      }
      // this boolean attribute is for using first step validation
      this.passFirstRequire = false;
      // validation general errors
      let isErrorExist = this.formFields.some((field) => field.errors.length > 0)
      if(isErrorExist) {
        this.disabledBtn = true;
        return
      }
      this.disabledBtn = false; 
      this.$emit('watch-changes'); 
    },
    showPassword(type, index) {
      if(type === "password"){
        this.formFields[index]["type"] = "text";
      } else {
        this.formFields[index]["type"] = "password"
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  &__btn-container{
    display: flex;
    justify-content: end;
    &__btn-save {
      height: 35px;
      font-size: 12px;
    }
  }
  &__input-password {
    width: 91%;
    &--icon {
      font-size: 15px;
      color: #a2acc4;
    }
    &--container-icon {
      cursor: pointer;
      height: 35px;
    }
  }
  &__box_error {
    padding-bottom: 10px;
  }
}
.input-group-text {
  padding: 0.375rem 10px;
}
::v-deep .modal-title {
  font: 18px "Oxygen";
  color: #2c3852;
  font-weight: 700;
  }
::v-deep .d-block {
  font: 14px "Oxygen";
  color: #2c3852;
}
</style>
