import { mapActions } from "vuex";
export const socialNetwork = {
  props: {
    allDriversToAdd: {
      type: Array,
      default: () => {
        return [];
      },
    },
    allIscDriversToAdd: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showBtnDrivers: {
      type: Boolean,
      default: true,
    },
    formContext: { // Update context
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data() {
    return {
      client_id:
        JSON.parse(sessionStorage.getItem("userObj")).id_cliente.toString() ||
        null,
      newDriverPage: "",
      buttonDisabled: true,
      warningText: "",
      termError: [],
      isBusyTable: false,
      newDriverPageSN: "", // this attribute is for update Listen context
    };
  },
  computed: {
    allDrivers: function () {
      return [...this.allIscDriversToAdd, ...this.allDriversToAdd].sort(
        (a, b) => a.added - b.added
      );
    },
  },
  watch: {
    newDriverPage: {
      immediate: true,
      handler: function (x) {
        if (this.newDriverPage.trim().length > 0) {
          this.buttonDisabled = false;
        } else {
          this.buttonDisabled = true;
        }
      },
    },
    newDriverPageSN: {
      // for updating a context
      immediate: true,
      handler: function (x) {
        this.checkPage();
      },
    },
  },
  methods: {
    ...mapActions({
      // for updating context
      addKeywordListen: "settings/listen/ADD_KEYWORD_SN",
      addGeoKeywordListen: "settings/listen/ADD_GEO_KEYWORD",
      deleteKeywordListen: "settings/listen/DELETE_KEYWORD_SN",
      setActiveToggleKeyword: "settings/listen/ACTIVE_TOGGLE_KEYWORD",
      setActiveToggleKeywordTw: "settings/listen/ACTIVE_TOGGLE_KEYWORD_TW",
    }),
    // this function works only for two components IG and LK
    closeIscDriverModalIGAndLK(driver) {
      if (driver) {
        if (this.allIscDriversToAdd.some((d) => d.mask == driver.mask)) {
          this.showWarning("Driver already added");
        } else {
          const newDriver = {
            ...driver,
            added: new Date().valueOf(),
          };
          this.$emit("iscDriverToAdd", newDriver);
        }
      }
    },
    deleteDriver: function (driver) {
      if (driver.geo_dashboard) {
        this.$emit("deleteIscDriverToAdd", driver.added, "added");
      } else {
        this.$emit("deleteDriverToAdd", driver.added, "added");
      }
    },
    errorTerm(errors) {
      this.termError = errors;
    },
    showWarning: function (message) {
      this.warningText = message;
      setTimeout(() => (this.warningText = ""), 5000);
    },
    activeLoadingSn(value) {
      // for updating context
      this.isBusyTable = value;
      if (value === true) {
        this.buttonDisabled = true;
      } else {
        this.checkPage();
      }
    },
    checkPage() {
      // for updating context
      if (
        this.newDriverPageSN.trim().length > 0 &&
        this.termError.length === 0
      ) {
        this.buttonDisabled = false;
      } else {
        this.buttonDisabled = true;
      }
    },
    getAllKeywordsLength(){
      return this.formContext.twitterKeywords.length +
      this.formContext.facebookKeywords.length +
      this.formContext.instagramKeywords.length +
      this.formContext.linkedinKeywords.length +
      this.formContext.newspaperKeywords.length;
    },
    //* UTILS METHODS 
    getGraphQlResponseError(){
      let dataError = sessionStorage.getItem("errorMessage");
      return dataError.replace("GraphQL error: ", "");
    },
    emitError(text){
      this.$emit('trigger-error', text);
    },
    mainProcessErrorGraphQl(){
      let msgError = this.getGraphQlResponseError();
      this.emitError(msgError);
      sessionStorage.removeItem("errorMessage");
    }
  },
  mounted(){
    //removing error message from GraphQl any time the user changes the components
    sessionStorage.removeItem("errorMessage");
  }
};
