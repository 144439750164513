import { simpleHandlerRespAxios} from "../../../../lib/handleResponse";

export default {
  async  ADD_CONSUME_LIMITS_CLIENT(_, idClient){
    try {
      const urlPlanIscFunction = `${ process.env.URL_PLAN_ISC }/add-client-limits `
      return await simpleHandlerRespAxios('post', urlPlanIscFunction, { idClient } );
    } catch (error) {
      console.log("[Error] in function: ADD_CONSUME_LIMITS_CLIENT ", error);
    }
  },
}