export const nwsPaperMixin = {

  data() {
    return {
      allNewspapers: [],
      //nwsCountries: [],
      nwsNames: [],
      nwsUrls: [],
      nwsCountrySelected: null,
      nwsNameSelected: null,
      nwsUrlSelected: null,
      nwsToAdd: null,
      countries: [
        {
          id: "2dfa9ecb0179a4e4",
          name: "Perú",
        },
        {
          id: "47a3cf27863714de",
          name: "Chile",
        },
      ],

      fields: [
        { key: "newspaper_name", class: "text-row-01" },
        {
          key: "page_url",
          class: "text-row-02",
        },
        {
          key: "delete",
        },
        { key: "active" },
      ],
    };
  },
  async created() {
    console.log("Mounted newspaper");
    await this.getNewspaperList();
  },
  methods: {
    //* Dropdowns methods
    // Selected place 
    changeSelectPlace(countryName) {
      this.nwsToAdd = null;
      this.nwsNameSelected = null;
      this.nwsUrlSelected = null;
      this.nwsCountrySelected = countryName;
      this.nwsNames = this.filterNwsNames();
    },
    filterNwsNames(){
      const names = new Set();
      return this.allNewspapers
        .filter((n) => {
          if (n.place_id == this.nwsCountrySelected.id) return n;
        })
        .reduce((acc, { id, newspaper_name }, index) => { 
          if(names.has( newspaper_name)) return acc;
          names.add(newspaper_name);
          acc.push({
            id,
            name: newspaper_name 
          });  
          return acc;
        }, []);
    },
    // === END selected place ===
    changeSelectName(newspaperName) {
      this.nwsToAdd = null;
      this.nwsUrlSelected = null;
      this.nwsNameSelected = newspaperName;
      this.nwsUrls = this.allNewspapers
        .filter((n) => {
          if (
            n.place_id == this.nwsCountrySelected.id &&
            n.newspaper_name == this.nwsNameSelected.name
          )
            return n;
        })
        .map((e) => ({ id: e.id, name: e.page_url }));
    },
    changeSelectUrl(newspaperUrl) {
      this.nwsUrlSelected = newspaperUrl;
      this.nwsToAdd = this.allNewspapers.filter((n) => {
        if (
          n.place_id == this.nwsCountrySelected.id &&
          n.newspaper_name == this.nwsNameSelected.name &&
          n.page_url == this.nwsUrlSelected.name
        )
          return n;
      })[0];
    },
    cleanSelects() {
      this.nwsToAdd = null;
      this.nwsNameSelected = null;
      this.nwsUrlSelected = null;
      this.nwsCountrySelected = null;
      //this.fillSelects();
    },
    /* fillSelects() {
      const nwsPlacesTemp = []
      this.allNewspapers.map((d) => {
        nwsPlacesTemp.push(d.place_id);
        this.nwsNames.push(d.newspaper_name);
        this.nwsUrls.push(d.page_url);
      });
      nwsPlacesTemp.map((place) => {
        const pais = this.paises.find((p) => { if (p.id == place && p.name) return p.name });
        this.nwsCountries.push(pais.name);
      });
    }, */
    //* Services 
    async getNewspaperList() {
      try {
        this.allNewspapers = [];
        const {
          data: { getNewspapersDrivers },
        } = await this.$store.dispatch("settings/listen/GET_NEWSPAPER_LIST");
        if (getNewspapersDrivers) {
          this.allNewspapers = getNewspapersDrivers;
          //this.fillSelects();
        } else {
          throw new Error("Error to validate Newspaper");
        }
      } catch (error) {
        this.allNewspapers = [];
        console.error(error);
        throw error;
      }
    },
  },
};