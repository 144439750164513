export default {
  geoDrivers: null,
  geoDriversFb: null,
  geoDriversTw: null,
  geoDriversIg: null,
  geoDriversLk: null,
  allAddedIscDrivers: [],
  allAddedDrivers: [],
  nameContext: '',
  globalVarContext: null,
  globalIscDrivers: []
};
