var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-text" },
    [
      _c("b-form-input", {
        staticClass: "input-text__context-name",
        attrs: {
          id: _vm.id,
          type: _vm.type,
          placeholder: _vm.placeholder,
          disabled: _vm.disabled
        },
        model: {
          value: _vm.model,
          callback: function($$v) {
            _vm.model = $$v
          },
          expression: "model"
        }
      }),
      _vm._v(" "),
      _vm.errors.length > 0
        ? _vm._l(_vm.errors, function(error, index) {
            return _c("div", { key: index }, [
              _c("div", { staticClass: "message-error" }, [
                _c("i", { staticClass: "fa fa-exclamation-circle" }),
                _vm._v(" " + _vm._s(error.message))
              ])
            ])
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }