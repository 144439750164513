var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "social-container" }, [
    _vm.showIconTwitter
      ? _c("i", { staticClass: "fab fa-twitter text-twitter mr-1" })
      : _vm._e(),
    _vm._v(" "),
    _vm.showIconFacebook
      ? _c("i", { staticClass: "fab fa-facebook  text-facebook mr-1" })
      : _vm._e(),
    _vm._v(" "),
    _vm.showIconInstagram
      ? _c("i", { staticClass: "fab fa-instagram  text-instagram mr-1" })
      : _vm._e(),
    _vm._v(" "),
    _vm.showIconLinkedin
      ? _c("i", { staticClass: "fab fa-linkedin text-linkedin mr-1" })
      : _vm._e(),
    _vm._v(" "),
    _vm.showIconNewspaper
      ? _c("i", { staticClass: "fas fa-newspaper mr-1" })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }