<template>
  <div>
    <b-tooltip class="tooltipBlue" :target="target" :title="title" triggers="hover">
      {{ message }}
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: "CpTooltipCustom",
  data() {
    return {};
  },
  props: {
    target: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped></style>
