
import { mapGetters, mapActions } from "vuex";
export const userObjMixin = {
  computed: {
    ...mapGetters({
      userInfo: "settings/profile/getObjUser",
    }),
  },
  methods: {
    ...mapActions({
      getCurrentInfoUser: "settings/profile/GET_OBJ_CURRENT_USER",
    }),
    async setCurrentObjUser() {
      try {
        if (!this.userInfo) await this.getCurrentInfoUser();
      } catch (error) {
        this.errorModal("Server fails to get current info User");
      }
    },
  },
};