/* eslint-disable no-undef */
export function handleError(error) {
  console.error(error.message);
  if (error.message == "GraphQL error: Not authorized!") {
    sessionStorage.clear();
    window.location.href = `${process.env.URL_MONITOR}/login`;
  } else {
    if (sessionStorage.getItem("errorMessage") === null)
      sessionStorage.setItem("errorMessage", error.message);
    console.error(error);
  }
  return {
    data: null,
    error: error.message,
  };
}
