var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: "",
            "hide-footer": "",
            "no-enforce-focus": true,
            "no-close-on-backdrop": _vm.noCloseBackdrop
          },
          model: {
            value: _vm.model,
            callback: function($$v) {
              _vm.model = $$v
            },
            expression: "model"
          }
        },
        [
          _vm.showBtnClose
            ? _c("div", [_c("CloseButton", { on: { close: _vm.close } })], 1)
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "container-alerts" }, [
            _c("div", { staticClass: "flex-center " }, [
              _vm.showMainIcon
                ? _c("i", {
                    staticClass: "flex-center icon",
                    class: _vm.icon,
                    style: { color: _vm.iconColor }
                  })
                : _c(
                    "div",
                    { staticClass: "spinner" },
                    [
                      _c("b-spinner", {
                        attrs: { variant: "primary", label: "Spinning" }
                      })
                    ],
                    1
                  )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "flex-center paragraph title" }, [
              _c("strong", [_vm._v(_vm._s(_vm.title))])
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "flex-center paragraph" }, [
              _vm._v(_vm._s(_vm.paragraph))
            ]),
            _vm._v(" "),
            _vm.showBtn
              ? _c(
                  "div",
                  { staticClass: "flex-center w-100 container-button" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "px-3 paragraph button",
                        attrs: { variant: "primary" },
                        on: { click: _vm.sendResult }
                      },
                      [_vm._v(_vm._s(_vm.buttonMessage))]
                    )
                  ],
                  1
                )
              : _c("div", { staticClass: "container-button" })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }