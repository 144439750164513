/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": process.env.APP_COGNITO_POOL_ID,
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": process.env.APP_USERS_POOLS_ID,
  "aws_user_pools_web_client_id": process.env.APP_USERS_POOLS_WEB_CLIENT_ID,
  "oauth": {},
  "aws_appsync_graphqlEndpoint": process.env.APP_AWS_GRAPHQL,
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};



export default awsmobile;
