<template>
  <div>
    <b-tooltip
      :target="target"
      :title="tooltipTitle"
      triggers="hover"
      custom-class="tooltipDouble"
      variant="light"
    >
      <div class="containerDivs">
        <div
          class="containerDiv1 flex-center"
          :style="{
            height: heightSubTitle1,
            background: backgroundColorSubtitle1,
            color: colorTextSubtitle1,
            width: widthSubtitle1,
            'text-align': textAlignSubtitle1 
          }"
        >
          <p class="title">
            <strong>{{ title }}</strong>
          </p>
          <p class="subTitle1">{{ subTitle1 }}</p>
        </div>
        <div
          class="containerDiv2 flex-center"
          :style="{ height: heightSubTitle2, color: colorTextSubtitle2, 'text-align': textAlignSubtitle2 }"
        >
          <p class="subTitle2">{{ subTitle2 }}</p>
        </div>
      </div>
    </b-tooltip>
  </div>
</template>

<script>

export default {
  name: "CpTooltipDoubleCustom",
  data() {
    return {};
  },
  props: {
    target: {
      type: String,
      default: "",
    },
    tooltipTitle: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subTitle1: {
      type: String,
      default: "",
    },
    subTitle2: {
      type: String,
      default: "",
    },
    heightSubTitle1: {
      type: String,
      default: "",
    },
    heightSubTitle2: {
      type: String,
      default: "",
    },
    backgroundColorSubtitle1: {
      type: String,
      default: "#E5F6FF",
    },
    colorTextSubtitle1: {
      type: String,
      default: "#2C3852",
    },
    colorTextSubtitle2: {
      type: String,
      default: "#6B738B",
    },
    widthSubtitle1: {
      type: String,
      default: "none",
    },
    textAlignSubtitle1 : {
      type: String,
      default: "center",
    },
    textAlignSubtitle2 : {
      type: String,
      default: "center",
    }
  },
  methods: {},
  created() {},
};
</script>

<style lang="css" scoped>
::v-deep .tooltip-inner {
  padding: 0px;
  background: unset !important;
  width: 239px;
}
/* ::v-deep .arrow::before {
      border-top-color: transparent !important;
  } */
.containerDiv1 {
  font-family: "Oxygen";
  font-style: normal;
  /* font-weight: 700; */
  font-size: 11px;
  line-height: 14px;
  color: #2c3852;
  padding: 0rem 1rem;
  /* background: #E5F6FF; */
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  flex-direction: column;
}
.subTitle1 {
  padding-top: 0.5rem;
}
.containerDiv2 {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #6b738b;
  padding: 0rem 1rem;
  background: #ffffff;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.containerDivs {
  box-shadow: 0px 2px 6px rgba(107, 115, 139, 0.2);
  border-radius: 0.5rem;
  width: 239px;
}
</style>
