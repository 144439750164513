
export default {
  /* SET_GEO_DRIVERS: (state, geoDrivers) => {
    state.geoDrivers = geoDrivers;
  },
  SET_GEO_DRIVERS_FB: (state, geoDriversFb) => {
    state.geoDriversFb = geoDriversFb;
  },  
  SET_GEO_DRIVERS_TW: (state, geoDriversTw) => {
    state.geoDriversTw = geoDriversTw;
  },*/  
};
