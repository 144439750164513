var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            "header-class": "border-0 pr-5",
            "header-close-variant": "primary",
            "hide-footer": "",
            "no-enforce-focus": true
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header-close",
              fn: function() {
                return [_c("icon-close-x")]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.model,
            callback: function($$v) {
              _vm.model = $$v
            },
            expression: "model"
          }
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "containerIscModal" }, [
            _c("p", {
              staticClass: "pMessage",
              domProps: { innerHTML: _vm._s(_vm.message) }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex containerButtonModal" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "px-3 style-button",
                    attrs: { variant: "primary" },
                    on: { click: _vm.sendResult }
                  },
                  [_vm._v("Ok")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }