import {
  handlerRespAxiosMonitorOverview,
} from "../../../../lib/handleResponse";

export default {
  //* IMPORTANT: You can find some service in settings/monitor (back-overview)
  //** SERVICE: BACK-OVERVIEW */
  //* QUERIES
  async GET_ACCOUNT_DETAIL(_, { account_id, rangeDetail, timezoneId }) {
    try {
      return await handlerRespAxiosMonitorOverview(
        "get",
        `/accounts/${account_id}?init=${rangeDetail[0]}&end=${rangeDetail[1]}&timezone_id=${timezoneId}`,
        ""
      );
    } catch (error) {
      console.log("[Error] in function: GET_ACCOUNT_DETAIL (Overview)", error);
      throw error;
    }
  },
  async GET_ACCOUNT_AUDIENCE(_, { account_id, rangeDetail, timezoneId }) {
    try {
      return await handlerRespAxiosMonitorOverview(
        "get",
        `/accounts/${account_id}/audience?init=${rangeDetail[0]}&end=${rangeDetail[1]}&timezone_id=${timezoneId}`,
        ""
      );
    } catch (error) {
      console.log(
        "[Error] in function: GET_ACCOUNT_AUDIENCE (Overview)",
        error
      );
      throw error;
    }
  },
  async GET_ACCOUNT_POSTS(_, { account_id, rangeDetail, timezoneId }) {
    try {
      return await handlerRespAxiosMonitorOverview(
        "get",
        `/accounts/${account_id}/posts?init=${rangeDetail[0]}&end=${rangeDetail[1]}&timezone_id=${timezoneId}`,
        ""
      );
    } catch (error) {
      console.log("[Error] in function: GET_ACCOUNT_POSTS (Overview)", error);
      throw error;
    }
  },
  async GET_ACCOUNT_ENGAGED_USERS(_, { account_id, rangeDetail, timezoneId }) {
    try {
      return await handlerRespAxiosMonitorOverview(
        "get",
        `/accounts/${account_id}/engage?init=${rangeDetail[0]}&end=${rangeDetail[1]}&timezone_id=${timezoneId}`,
        ""
      );
    } catch (error) {
      console.log(
        "[Error] in function: GET_ACCOUNT_ENGAGED_USERS (Overview)",
        error
      );
      throw error;
    }
  },
  async GET_ACCOUNT_INTERACTIONS(_, { account_id, rangeDetail, timezoneId }) {
    try {
      return await handlerRespAxiosMonitorOverview(
        "get",
        `/accounts/${account_id}/interactions?init=${rangeDetail[0]}&end=${rangeDetail[1]}&timezone_id=${timezoneId}`,
        ""
      );
    } catch (error) {
      console.log(
        "[Error] in function: GET_ACCOUNT_INTERACTIONS (Overview)",
        error
      );
      throw error;
    }
  },
  //* POSTS OR UPDATES (MUTATIONS)
  /**
   ** SERVICE: BACK-OVERVIEW
   * insert Accounts( fan pages)
   * función que inserta multiples cuentas y devuelve la cantidad de cuentas que no se pudieron insertar
   * {Array} accounts
   * @param {*} _
   * @param {*} data
   * @return {*}
   */
  async INSERT_ACCOUNTS(_, data) {
    try {
      return await handlerRespAxiosMonitorOverview(
        "post",
        "/accounts/add-multiple",
        data
      );
    } catch (error) {
      console.log("[Error] in function: INSERT_ACCOUNTS ", error);
      throw error;
    }
  },
  /**
   ** SERVICES BACK-OVERVIEW
   * update accounts from edit, overview/accounts
   * @param {*} _
   * @param {*} data
   * @return {*}
   */
  async UPDATE_ACCOUNTS(_, data) {
    try {
      return await handlerRespAxiosMonitorOverview(
        "put",
        "/accounts/edit-multiple",
        data
      );
    } catch (error) {
      console.log("[Error] in function: INSERT_ACCOUNTS ", error);
      throw error;
    }
  },
/**
 ** SERVICE: BACK-OVERVIEW
 * delete an account (overview/accounts)
 * @param {*} _
 * @param {*} { account_id }
 * @return {*} 
 */
async DELETE_ACCOUNT(_, { account_id }) {
    try {
      return await handlerRespAxiosMonitorOverview(
        "delete",
        `/accounts/${account_id}`,
        ""
      );
    } catch (error) {
      console.log("[Error] in function: DELETE_ACCOUNT ", error);
      throw error;
    }
  },
async VALIDATE_DUPLICATED_NAME(_, data) {
    try {
      return await handlerRespAxiosMonitorOverview(
        "post",
        "/accounts/validate-name",
        data
      );
    } catch (error) {
      console.log("[Error] in function: VALIDATE_DUPLICATE_NAME ", error);
      throw error;
    }
  },
};
