var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-button" }, [
    _c("button", { staticClass: "button", on: { click: _vm.close } }, [
      _c(
        "svg",
        {
          attrs: {
            width: "15",
            height: "15",
            viewBox: "0 0 15 15",
            fill: _vm.fill,
            xmlns: "http://www.w3.org/2000/svg"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M8.37918 7.50439L14.8091 1.07451C15.0572 0.834832 15.0641 0.439376 14.8244 0.19121C14.5848 -0.056956 14.1893 -0.0638373 13.9411 0.175837C13.9359 0.180852 13.9308 0.185976 13.9258 0.19121L7.49585 6.62109L1.06596 0.191174C0.817799 -0.0485008 0.422343 -0.0416195 0.182669 0.206547C-0.0511493 0.448637 -0.0511493 0.832416 0.182669 1.07451L6.61255 7.50439L0.182669 13.9343C-0.0612151 14.1782 -0.0612151 14.5737 0.182669 14.8176C0.426589 15.0614 0.822045 15.0614 1.06596 14.8176L7.49585 8.38769L13.9257 14.8176C14.1739 15.0572 14.5694 15.0504 14.809 14.8022C15.0428 14.5601 15.0428 14.1763 14.809 13.9343L8.37918 7.50439Z",
              fill: _vm.fill
            }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }