var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-v-select" },
    [
      _c("v-select", {
        attrs: {
          options: _vm.options,
          textProp: _vm.textKey,
          valueProp: _vm.valueKey,
          disabled: _vm.disabled
        },
        model: {
          value: _vm.model,
          callback: function($$v) {
            _vm.model = $$v
          },
          expression: "model"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }