var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tooltip",
        {
          attrs: {
            target: _vm.target,
            title: _vm.title,
            triggers: "hover",
            "custom-class": "tooltip-icon",
            variant: "light",
            placement: _vm.placement
          }
        },
        [
          _c("div", { staticClass: "tooltip-icon-title" }, [
            _c("i", {
              staticClass: "fa fa-circle",
              style: { color: _vm.colorIcon }
            }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.messageTitle))])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "tooltip-icon-subtitle" }, [
            _vm._v(_vm._s(_vm.messageSubTitle))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }