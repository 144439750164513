var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "warning-info",
      style: {
        padding: _vm.paddingContainer,
        "justify-content": _vm.justifyContent
      }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "warning-info-text" }, [
        _vm._v("\n    " + _vm._s(_vm.message) + "\n  ")
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "warning-info-icon" }, [
      _c("i", { staticClass: "fa fa-info-circle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }