export default {
  SET_DATA_GEO_DRIVERS: (state, respGeoDrivers) => {
    state.respGeoDrivers = respGeoDrivers;
  },
  RESET_GEO_DRIVERS: state => {
    state.respGeoDrivers = null;
  },
  SET_CHANGE_DATE_RANGE_HEADER: (state, change) => {
    state.changeDateRangeHeader = change;
  }
};
