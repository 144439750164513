<template>
  <div class="context-box-sn">
    <cp-panel-s-n
      :driverType="'lk'"
      :text-value.sync="newDriverPageSN"
      :warningText="warningText"
      :buttonDisabled="buttonDisabled"
      :errorSubject="'URL'"
      :showBtnDrivers="showBtnDrivers"
      placeholder="Paste Url Profile Linkedin Page..."
      @no-errors="errorTerm"
      @closeIscDriverModal="closeIscDriverModal"
      @click-create="addNewDriver"
    />
    <!-- TABLE List add items    -->
    <b-row class="context-box-sn__table">
      <b-col>
        <div class="context-box-sn__table__body">
          <cp-table-driver
            :items="formContext.linkedinKeywords"
            @delete-item="deleteKeyword"
            @update-toggle-active="toggleActive"
            :fields="fields"
            title="Profile Page List"
            :isBusy="isBusyTable"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CpPanelSN from "./components/CpPanelSN.vue";
import CpTableDriver from "../../components/table/CpTableDriver.vue";
import { socialNetwork } from "../../../mixins/index";

export default {
  name: "UpdateLinkedinDrivers",
  mixins: [socialNetwork],
  components: { CpPanelSN, CpTableDriver },
  data() {
    return {
      fields: [
        { key: "name", class: "text-row-01" },
        {
          key: "mask",
          class: "text-row-02",
        },
        {
          key: "delete",
        },
        { key: "active" },
      ],
    };
  },
  methods: {
    addNewDriver: async function () {
      this.buttonDisabled = true;
      if (!this.newDriverPageSN || this.termError.length > 0) return;
      try {
        this.activeLoadingSn(true);
        const {
          data: { lkPageCheck },
        } = await this.$store.dispatch("settings/listen/CHECK_LK_PAGE", {
          page_url: this.newDriverPageSN.trim(),
        });
        if (lkPageCheck && lkPageCheck.public === true) {
          const newDriver = {
            page_url: this.newDriverPageSN.trim(),
            page_id: lkPageCheck.id,
            mask: lkPageCheck.mask,
            name: lkPageCheck.name,
            active: true,
          };
          if (
            this.formContext.linkedinKeywords.some(
              (d) => d.page_id == newDriver.page_id
            )
          ) {
            this.showWarning("Fanpage already added");
          } else {
            const variables = {
              context_id: this.formContext.contextId,
              driver: newDriver,
              active: newDriver.active,
              type: "lk",
            };
            const {
              data: { addKeyword },
            } = await this.addKeywordListen(variables);

            if (addKeyword.id) {
              this.formContext.linkedinKeywords.push(addKeyword);
              this.newDriverPageSN = "";
            } else {
              this.emitError("Server fails to add a Linkedin's Fanpage.")
            }
          }
        } else {
          this.emitError("Server fails to check a Linkedin's fanpage.");
        }
      } catch (error) {
        console.error("[Error] in function addNewDriver(LK)", error);
        this.mainProcessErrorGraphQl();
      } finally {
        this.activeLoadingSn(false);
      }
    },
    async toggleActive(id, status) {
      this.activeLoadingSn(true);
      if (id) {
        const variables = {
          context_id: this.formContext.contextId,
          keyword_id: id,
          type: "lk",
          active: status,
        };
        try {
          await this.setActiveToggleKeyword(variables);
          /* if (toggleActiveKeyword[0].active === status) {
            this.formContext.linkedinKeywords = this.formContext.linkedinKeywords.map(function (k) {
              if (k.id == id) {
                k.active = status;
              }
              return k;
            });
          } */
        } catch (error) {
          // If there is an error, get back from old formContext.linkedinKeywords
          this.formContext.linkedinKeywords = this.formContext.linkedinKeywords.map(
            function (k) {
              if (k.id === id) {
                k.active = !status;
              }
              return k;
            }
          );
          console.error("[Error] in function toggleActive(lk) ", error);
          this.emitError("There was an error to change a keyword'status.")
        } finally {
          this.activeLoadingSn(false);
        }
      }
    },
    async deleteKeyword(item) {
      if (this.getAllKeywordsLength() > 1) {
        this.activeLoadingSn(true);
        if (item.id) {
          const variables = {
            context_id: this.formContext.contextId,
            keyword_id: item.id,
            type: "lk",
          };
          try {
            const {
              data: { deleteKeyword },
            } = await this.deleteKeywordListen(variables);

            if (deleteKeyword == "keyword deleted") {
              this.formContext.linkedinKeywords = this.formContext.linkedinKeywords.filter(
                (k) => k.id !== item.id
              );
            } else {
              this.emitError("Server fails to delete a driver LK");
            }
          } catch (error) {
            console.error("[Error] in function  deleteKeyword (LK) ", error);
            this.mainProcessErrorGraphQl();
          } finally {
            this.activeLoadingSn(false);
          }
        }
      } else {
        //Eliminar contexto (para que no esté vacío)
        //console.log("Solo queda un driver");
        this.emitError("At least you must have one driver in your context.")
      }
    },
    async closeIscDriverModal(driver) {
      if (driver) {
        if (this.formContext.linkedinKeywords.some((d) => d.id === driver.id)) {
          this.showWarning("Driver already added");
        } else {
          this.activeLoadingSn(true);
          const variables = {
            context_id: this.formContext.contextId,
            keyword_id: driver.id,
            active: true,
            type: "lk",
          };
          try {
            const {
              data: { addGeoKeyword },
            } = await this.addGeoKeywordListen(variables);

            if (addGeoKeyword.id) {
              addGeoKeyword.active = true;
              this.formContext.linkedinKeywords.push(addGeoKeyword);
            } else {
              this.emitError("Server fails to add a Driver LK.")
            }
          } catch (error) {
            console.error("[Error] in function closeIscDriverModal(LK)", error);
            this.mainProcessErrorGraphQl();
          } finally {
            this.activeLoadingSn(false);
          }
        }
      }
    },
  },
};
</script>

<style></style>
