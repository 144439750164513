<template>
  <div class="social-container">
    <i
      v-if="showIconTwitter" 
      class="fab fa-twitter text-twitter mr-1"
    >
    </i>
    <i 
      v-if="showIconFacebook"
      class="fab fa-facebook  text-facebook mr-1"
    >
    </i>

    <i
      v-if="showIconInstagram" 
      class="fab fa-instagram  text-instagram mr-1"
    ></i>

    <i
      v-if="showIconLinkedin" 
      class="fab fa-linkedin text-linkedin mr-1"
    ></i>
    <i
      v-if="showIconNewspaper" 
      class="fas fa-newspaper mr-1"
    ></i>
  </div>
</template>

<script>
export default {
  name: "CpIconSocialMedia",
  data() {
    return {
      showIconTwitter: {
        type: Boolean,
        default: true,
      },
      showIconFacebook: {
        type: Boolean,
        default: true,
      },
      showIconInstagram: {
        type: Boolean,
        default: true,
      },
      showIconLinkedin: {
        type: Boolean,
        default: true,
      },
      showIconNewspaper: {
        type: Boolean,
        default: true 
      }
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.social-container {
}
</style>
