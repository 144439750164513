<template>
  <div>
    <div 
      class="custom-select selectCustom d-flex align-items-center"
      :class="[disabled ? 'disabledSelect' : '']"
    >
      <multiselect
        class=""
        v-model="valueSelected"
        :options="options"
        :disabled="disabled"
        :searchable="false"
        :close-on-select="true"
        :custom-label="nameWithLang"
        label="name"
        @select="selectValue"
      >
      </multiselect>
    </div>
  </div>
</template>
  
<script>
import Multiselect from 'vue-multiselect';
export default {
  name: "SelectCustom",
  components: {
    Multiselect
  },
  props: {
    selected: {
      type: Object,
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valueSelected: this.selected,
    };
  },
  methods: {
    selectValue(change) {
      this.$emit("selectValue", change);
    },
    nameWithLang ({ text }) {
      return `${text}`
    }
  },
};
</script>
<style lang="scss" scoped>
.custom-select {
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40'  d='M2 0L0 1zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px !important;
}
.disabledSelect {
  background: #ccc !important;
  color: linen;
  opacity: 1;
}
::v-deep .selectCustom {
  width: 250px !important;
  position: unset !important;
  height: 35px;
  border-radius: 3px;
  border: solid 1px #C5D0E4;
  box-shadow: 0px 2px 5px 0px rgba(197, 208, 228, 0.62) inset;
  background-color: #fff;
  padding: 0;
  .multiselect{
    width: 100%;
    &__single{
      font-family: Oxygen;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #5d6b88;
      padding-left: 1rem;
    }
    &__content-wrapper{
      position: absolute;
      margin-top: 5px;
      border-radius: 3px;
      border: solid 1px #C5D0E4;
      box-shadow: 0px 2px 5px 0px rgba(197, 208, 228, 0.62) inset;
      width: 248px;
      height: 90px;
      overflow-x: auto;
      .multiselect__content{
        display: block !important;
        list-style: none;
        margin: 0;
        padding: 0;
        .multiselect__element{
          font-family: 'Oxygen';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #2C3852;
          height: 44px;
          background: #fff;
          display: flex;
          align-items: center;
          .multiselect__option{
            width: 100%;
            height: 100%;
            padding-left: 1rem;
            display: flex;
            align-items: center;
            text-transform: capitalize;
          }
        }
        .multiselect__element:hover{
          background: #EBF8FF;
        }
      }
    }
    &__placeholder{
      font-size: 12px;
      padding-left: 16px;
    }
  }
}

@media (max-width: 1400px) {
  ::v-deep .selectCustom {
    width: 210px !important;
    .multiselect__content-wrapper{
      width: 208px !important;
    }
  }
}

@media (max-width: 1250px) {
  ::v-deep .selectCustom {
    width: 170px !important;
    .multiselect__content-wrapper{
      width: 168px !important;
    }
  }
}

@media (max-width: 1150px) {
  ::v-deep .selectCustom {
    width: 140px !important;
    .multiselect__content-wrapper{
      width: 139px !important;
    }
  }
}
</style>

  