import {
  handlerRespAxiosMonitorLogin,
  //handlerRespAxiosWithoutCredentials,
} from "../../../../lib/handleResponse";

export default {

  async IS_CLIENT_OR_USUARIO() {
    try {
      return await handlerRespAxiosMonitorLogin(
        "get",
        "monitor/is-client-or-usuario",
        ""
      );
    } catch (error) {
      console.log("[Error] in function: IS_CLIENT_OR_USUARIO", error);
      throw error;
    }
  },
}